<template>
<div>
    <span v-if="loading">
        <loader />
    </span>
    <span v-else>
        {{conversion}}
    </span>
</div>
</template>

<script>
import loader from './loader.vue';
export default {
    components: {
        loader
    },
    props: ["name", "date", "source","link"],
    data() {
        return {
            loading: true,
            conversion: 0,
            adrubt: ["Quicken Loans", "Mortgage.net", "HomeLoanAnalyst No SSN Req"],
        }
    },
    methods: {
        read() {
            let k = "quicken";
            let offer = this.name;
            if (this.name == 'Refi Click to Call') {
                k = 'r_click';
            } else if (this.name == 'Refi Live Transfer') {
                k = 'r_live';
            } else if (this.name == 'Purchase Data') {
                k = 'p_data';
            } else if (this.name == 'Purchase Live Transfer') {
                k = 'r_live';
            } else if (this.name == 'Rate Genie Refi Data') {
                k = 'r_data';
            } else if (this.name == 'Debt Data') {
                k = 'debt';
            } else if (this.name.includes('Call')) {
                offer= 'call'
            }else{
                k = this.name;
            }
            let data = {
                "source": this.source.vici_agent,
                "id" : this.source.id,
                "start": localStorage.getItem('start') + " 00:00:00",
                "end": localStorage.getItem('end') + " 23:59:59",
                "name": k,
                "aff_id" : this.source.aff_id,
                "offer_name": offer,
                "link" : this.link
            };
            let rand  = Math.random() * (2000 - 8000) + 2000;
            setTimeout(function() {
               console.log("wait")
            }, rand);
            this.axios.post("/lead/click", data).then(response => {
                this.conversion = response.data.converstion[0].count;
                this.loading = false;
            })
        }
    },
    created() {
        this.read();
    }
}
</script>
