<template>
<input type="checkbox" name="" id="menu-toggle">
<div class="overlay"><label for="menu-toggle">
    </label></div>
<div v-if="!showMessage">
    <div v-bind:class="{ 'hideDiv': !loading}">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#1d3f72" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
        </svg>
    </div>
</div>
<modelWindow v-if="showModel" @getDIDDebt="getDIDDebt" @CloseDialog="showModel=false;"></modelWindow>
<modelWindowDID v-if="showupdate" @CloseDialog="showupdate=false;"></modelWindowDID>

<div class="sidebar">
    <div class="sidebar-container">
        <div class="brand">
            <img src="@/assets/logonew.png" style="width:100px">
        </div>
        <div class="sidebar-avatar">
            <div>
                <img src="@/assets/ray.png" alt="avatar" v-if="userData.id==126">
                <img src="@/assets/user.jpeg" alt="avatar" v-else>
            </div>
            <div class="avatar-info">
                <div class="avatar-text">
                    <h6>{{userData.name}}</h6>
                    <small>Admin</small>
                </div>
            </div>
        </div>
        <div class="sidebar-menu">
            <ul>
                <li>
                    <router-link to="/"><span class="las la-home"></span><span>Home</span></router-link>
                </li>
                <li><a href="#" @click="readUsersAgain($event)"><span class="las la-users"></span><span>Refresh Affiliate List</span></a></li>
                <li><a href="#" @click="onLogout($event)"><span class="las la-power-off"></span><span>Logout</span></a></li>
            </ul>
            <ul>

            </ul>
        </div>
    </div>
</div>
<div class="main-content" v-if="selectedId==0">
    <p> Select Affiliate from the left menu to see stats.</p>
</div>
<div v-else>
    <div class="main-content" style="justify-content: left;" v-bind:class="{ 'hideDiv': loading}">
        <main style="width: 100% !important;">
            <section>
                <label for="menu-toggle" style="color:#ff118f; font-size:24px;">
                    <span class="las la-bars"></span>
                </label>
                <div class="header-wrapper">
                    <div class="header-title">
                        <h5>{{name}} Pricing</h5>
                    </div>
                </div>
                <br />
            </section>
            <section>
                <div class="container">
                    <table id="customers" style="width:100%">
                        <tr>
                            <th>Offer</th>
                            <th>Pricing</th>
                            <th>Link/DID</th>
                            <th>Action</th>
                        </tr>
                        <tbody class="testme" :key="pkey">
                            <tr v-for="(p,index) in pricingData">
                                <td><input type="text" v-model="pricingData[index].Name" placeholder="Enter Name"></td>
                                <td><input type="text" v-model="pricingData[index].pricing" placeholder="Enter Pricing"></td>
                                <td><input type="text" v-model="pricingData[index].link" placeholder="Enter Link"></td>
                                <td>
                                    <i style="color:red" class="fa fa-trash" @click="deleteOffer($event, index)"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <i class="fa fa-plus" @click="addMoreLinks($event)"></i>
                    <br /><br />
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary" @click="savePricing($event)">Save Pricing &amp; Links</button>
                        </div>
                        <div class="col">
                            <div class="col">
                                <button class="btn btn-primary" @click="showModel=true">Get Debt Click DID</button>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col">
                                <button class="btn btn-primary" @click="getDebtDID($event, 0)">Get Refi Click DID</button>
                            </div>
                        </div>

                        <div class="col">
                            <div class="col">
                                <button class="btn btn-primary" @click="getDebtDID($event, 2)">Get Medicare DID</button>
                            </div>
                        </div>

                        <div class="col">
                            <div class="col">
                                <button class="btn btn-primary" @click="showupdate=true">Update IVR On DID</button>
                            </div>
                        </div>
                    </div>
                    <p>Note: DID is olny required in Click To Call Offers</p>
                </div>
            </section>
        </main>
    </div>
</div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import Papa from 'papaparse';
import modelWindow from './didDialog.vue';
import modelWindowDID from './modelWindowDID.vue';
import Swal from 'sweetalert2'

export default {
    name: 'admin-home',
    components: {
        Datepicker,
        modelWindow,
        modelWindowDID
    },
    data() {
        return {
            showModel: false,
            totalVisits: 0,
            approved: 0,
            formVisits: 0,
            reportVisits: 0,
            conversion: 0,
            loading: true,
            date: null,
            graphKey: 1,
            visitData: [],
            shannon: 0,
            conversationData: [],
            sold: [],
            source: 'mortgageorganic',
            users: [],
            active_el: 1,
            name: '',
            selectedId: 0,
            showMessage: true,
            userData: [],
            pricingData: [],
            linkData: [],
            pkey: 1,
            selectedPhone:'',
            showupdate:false,
        }
    },
    mounted() {},
    computed: {
        getUsers() {
            return this.users;
        },
    },
    methods: {
        updateURL($event, phone){
            this.selectedPhone = phone;
            this.showupdate = true;
        },
        getDIDDebt(data){
            this.axios.post("/admin/did/debt/"+this.selectedId,data).then(response => {
                this.showModel = false;
                if (response.data.status == 'success') {
                    Swal.fire({
                        title: 'Success!',
                        text: response.data.did,
                        icon: 'success',

                    });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: "Unexpected error",
                        icon: 'error',

                    });
                }
            });
        },
        
        getDebtDID(event, val) {
            event.preventDefault();
            this.axios.post("/calls/"+val+"/" + this.selectedId).then(response => {
                if (response.data.status == 'success') {
                    Swal.fire({
                        title: 'Success!',
                        text: response.data.did,
                        icon: 'success',

                    });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: "Unexpected error",
                        icon: 'error',

                    });
                }
            });
        },
        deleteOffer(event, index) {
            event.preventDefault();
            this.pricingData.splice(index, 1);
            this.pkey++;
        },
        addMoreLinks(event) {
            event.preventDefault();
            this.pricingData.push({
                Name: '',
                name_internal: '',
                type: '',
                pricing: 50,
                link: '',
            });
            this.pkey++;
        },
        onLogout(event) {
            event.preventDefault();
            this.$emit('onLogout');
        },
        readSource(event, src, name, approved, id) {
            this.name = name;
            this.selectedId = id;
            this.showMessage = false;
            this.readPricing();
        },
        savePricing() {
            this.axios.post("/admin/links", {
                id: this.selectedId,
                pricing: this.pricingData,
                links: this.linkData
            }).then(response => {
                Swal.fire({
                    title: 'Success!',
                    text: "Pricing and Links Saved!",
                    icon: 'success',

                });
            });
        },
        readPricing() {
            this.axios.get("/admin/links/" + this.selectedId).then(response => {
                this.pricingData = response.data.data.pricing;
                this.linkData = response.data.data.links;
                if (this.linkData.length <= 0) {
                    this.linkData.push("http://therategenie.com/");
                }
                this.loading = false;
            });
        },
        readUsersAgain($event) {
            event.preventDefault();
            this.readUsers();
        },
        readUsers() {
            this.axios.get("/dash/users").then(response => {
                this.$store.dispatch("setAffiliate", this.users);
                if (this.userData.id == 148) {
                    response.data.users.forEach(element => {
                        if (element.aff_type == 2) {
                            this.users.push(element);
                        }
                    });

                } else {
                    this.users = response.data.users;
                }

            });
        }
    },
    created() {
        this.userData = this.$store.getters.getuserData;
        if (this.userData.id == 126) {
            this.selectedId = this.$route.params.id;
            this.name = this.$route.params.name;

            let temp = this.$store.getters.getaffiliate;
            if (temp <= 0) {
                this.readUsers();
            } else {
                if (this.userData.id == 148) {
                    temp.forEach(element => {
                        if (element.aff_type == 2) {
                            this.users.push(element);
                        }
                    });
                } else {
                    this.users = temp;
                }
            }
            this.readPricing();
        } else {
            window.location.href = "/";
        }

    }
}
</script>

<style scoped>
img {
    width: 100%;
    height: auto;
}

.m_title:first-letter {
    text-transform: uppercase
}

#menu-toggle {
    display: none;
}

#menu-toggle:checked~.sidebar {
    left: -345px;
}

#menu-toggle:checked~.main-content {
    margin-left: 0;
    width: 100vw;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    display: none;
    background-color: rgba(255, 255, 255, 0.5);
}

/* helper */
.text-danger {
    color: red;
}

.text-success {
    color: #2ec3a3;
}

.text-main {
    color: var(--color-main);
}

/*
Sidebar
*/
.sidebar {
    width: 345px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 1rem 1.2rem;
    transition: left 300ms;
}

.sidebar-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: var(--shadow);
    padding: 1.2rem;
    overflow-y: auto;
}

.sidebar-container::-webkit-scrollbar {
    width: 5px;
}

.sidebar-container::-webkit-scrollbar-track {
    box-shadow: var(--shadow);
}

.sidebar-container::-webkit-scrollbar-thumb {
    background-color: var(--main-accent);
    outline: 1px solid #ccc;
    border-radius: 2px;
}

.brand {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand h3 {
    color: #444;
    font-size: 3rem;
}

.brand h3 span {
    color: var(--color-main);
    font-size: 2.5rem;
    display: inline-block;
    margin-right: 0.5rem;
}

.sidebar-avatar {
    display: grid;
    grid-template-columns: 70px auto;
    align-items: center;
    border: 2px solid var(--main-accent);
    padding: 0.1rem 0.7rem;
    border-radius: 7px;
    margin: 2rem 0rem;
}

.sidebar-avatar img {
    width: 40px;
    border-radius: 10px;
    margin: 5px 0;
}

.avatar-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-menu li {
    margin-bottom: 2rem;
}

.sidebar-menu a {
    color: var(--main-text);
    display: block;
    padding: 0.7rem 0;
}

.sidebar-menu a.active {
    background-color: var(--main-accent);
    border-radius: 7px;
    padding: 0.8rem;
}

.sidebar-menu a span:first-child {
    display: inline-block;
    margin-right: 0.8rem;
    font-size: 1.5rem;
    color: var(--color-main);
}

.sidebar-card {
    background-color: var(--main-accent);
    padding: 1rem;
    margin-top: 2rem;
    box-shadow: var(--shadow);
    text-align: center;
    border-radius: 7px;
}

.side-card-icon span {
    font-size: 8rem;
    color: var(--color-main);
    display: inline-block;
}

.side-card-icon {
    margin-bottom: 0.8rem;
}

.side-card-icon+div {
    margin-bottom: 1rem;
}

.side-card-icon+div p {
    font-size: 0.8rem;
    color: #555;
}

.btn {
    padding: 0.7rem 1rem;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.btn span {
    font-size: 1.2rem;
    display: inline-block;
    margin-right: 0.7rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-main {
    background-color: var(--color-main);
    color: #fff;
}

/* main */
.main-content {
    margin-left: 345px;
    width: calc(100vw - 345px);
    padding: 1rem 2rem 1.2rem 1.2rem;
    transition: margin-left 300ms;
}

/* Header */
header {
    display: flex;
    padding-top: 0.5rem;
    justify-content: space-between;
}

.header-wrapper {
    display: flex;
}

.header-wrapper label {
    display: inline-block;
    color: var(--color-main);
    margin-right: 2rem;
    font-size: 1.8rem;
}

.header-wrapper label span:hover {
    cursor: pointer;
}

.header-title h5 {
    color: var(--main-text);
    font-weight: 600;
}

.header-title p {
    color: #666;
    font-size: 0.9rem;
}

.header-title p span {
    color: red;
    font-size: 1.2rem;
    display: inline-block;
    margin-left: 0.5rem;
}

.header-action button {
    padding: 0.85rem 2rem;
    font-size: 1rem;
}

main {
    padding: 2.5rem 0;
}

.analytics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2.5rem;
    margin-bottom: 3rem;
}

.analytic {
    box-shadow: var(--shadow);
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    padding-left: 2rem;
}

.analytic-info h4 {
    font-weight: 400;
    color: #555;
    font-size: 0.98rem;
}

.analytic-info h5 {
    color: var(--main-text);
    font-weight: 600;
}

.analytic-info h5 small {
    font-size: 0.8rem;
    font-weight: 700;
}

.analytic-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 0.8rem;
}

.section-head {
    font-size: 1.4rem;
    color: var(--main);
    font-weight: 600;
    margin-bottom: 1rem;
}

.block-grid {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 30% auto;
}

.rev-content {
    background-color: #fff;
    box-shadow: var(--shadow);
    border-radius: 15px;
    padding: 1rem 2rem;
    text-align: center;
}

.rev-content img {
    width: 180px;
    margin: 1.5rem 0;
    border-radius: 50%;
}

.rev-info {
    margin-bottom: 1rem;
}

.rev-info h3,
.rev-sum h4 {
    font-weight: 600;
    color: var(--main);
}

.rev-info h5,
.rev-sum h2 {
    font-weight: 400;
    margin-top: 0.4rem;
    color: #555;
}

.rev-info h5 small {
    font-size: 1rem;
}

.rev-sum {
    background-color: var(--main-accent);
    border: 1px solid var(--color-main);
    padding: 1rem;
    border-radius: 10px;
}

.graph-content {
    border-radius: 15px;
    box-shadow: var(--shadow);
    padding: 1rem;
    background-color: #fff;
}

.graph-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

.icon {
    height: 40px;
    width: 40px;
    border-radius: 7px;
    background-color: #eee;
    display: grid;
    place-items: center;
    font-size: 1.2rem;
    margin-right: 0.8rem;
}

.graph-head select {
    outline: none;
    background-color: #eee;
    height: 35px;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    border: none;
}

.overlay label {
    display: block;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1224px) {
    .sidebar {
        left: -345px;
        z-index: 30;
    }

    .main-content {
        width: 100vw;
        margin-left: 0;
    }

    #menu-toggle:checked~.sidebar {
        left: 0;
    }

    #menu-toggle:checked~.overlay {
        display: block;
        text-align: right;
    }
}

@media only screen and (max-width: 860px) {
    .analytics {
        grid-template-columns: repeat(2, 1fr);
    }

    .block-grid {
        grid-template-columns: 100%;
    }

    .revenue-card {
        order: 2;
    }
}

@media only screen and (max-width: 580px) {
    .analytics {
        grid-template-columns: 100%;
    }
}

@media only screen and (max-width: 500px) {

    .header,
    header-wrapper {
        align-items: center;
    }

    .header-title h5 {
        font-size: 1.2em;
    }

    .header-title p {
        display: none;
    }
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-size: 12px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}
</style>
