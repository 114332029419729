<template>
<div>
    <span v-if="loading">
        <loader />
    </span>
    <span v-else>
        {{conversion}}
    </span>
</div>
</template>

<script>
import loader from './loader.vue';
export default {
    components: {
        loader
    },
    props: ["name", "date", "source"],
    data() {
        return {
            loading: true,
            conversion: 0,
            adrubt: ["Quicken Loans", "Mortgage.net", "HomeLoanAnalyst No SSN Req"],
        }
    },
    methods: {
        read() {
            let k = "quicken";
            if (this.name == 'Refi Click to Call') {
                k = 'r_click';
            } else if (this.name == 'Refi Live Transfer') {
                k = 'r_live';
            } else if (this.name == 'Purchase Data') {
                k = 'p_data';
            } else if (this.name == 'Purchase Click to Call') {
                k = 'p_click';
            } else if (this.name == 'Purchase Live Transfer') {
                k = 'r_live';
            } else if (this.name == 'Rate Genie Refi Data') {
                k = 'r_data';
            } else if (this.name == 'Debt Data') {
                k = 'debt';
            } else if (this.name == 'Debt Click to Call') {
                k = 'debt_click';
            }else{
                k = this.name;
            }
            let data = {
                "source": this.source,
                "start": this.date[0] + " 00:00:00",
                "end": this.date[1] + " 23:59:59",
                "name": k,
                "offer_name": this.name
            };
            this.axios.post("/lead/source", data).then(response => {
                this.conversion = response.data.converstion[0].count;
                this.loading = false;
                if (k == 'quicken') {
                    localStorage.setItem(this.name,JSON.stringify(response.data));
                }
            })
        }
    },
    created() {
        this.read();
    }
}
</script>
