<template>
<input type="checkbox" name="" id="menu-toggle">
<div class="overlay"><label for="menu-toggle">
    </label></div>

<div class="sidebar">
    <div class="sidebar-container">
        <div class="brand">
            <img src="@/assets/logonew.png" style="width:100px">
        </div>
        <div class="sidebar-avatar">
            <div>
                <img src="@/assets/ray.png" alt="avatar" v-if="userData.id==126">
                <img src="@/assets/user.jpeg" alt="avatar" v-else>
            </div>
            <div class="avatar-info">
                <div class="avatar-text">
                    <h6>{{userData.name}}</h6>
                    <small>Admin</small>
                </div>
            </div>
        </div>
        <div class="sidebar-menu">
            <ul>
                <li v-if="userData.id==126"><a href="#" @click.prevent="showModel=true"><span class="las la-user-circle"></span><span>Add Affiliate</span></a></li>
                <li><a href="#" @click="readUsersAgain($event)"><span class="las la-users"></span><span>Refresh Affiliate List</span></a></li>
                <li v-if="userData.id==126">
                    <router-link to="/conver"><span class="las la-users"></span><span>Conversions &amp; Form Fills</span></router-link>
                </li>
                <li v-if="userData.id==126">
                    <router-link to="/call-logs"><span class="las la-phone"></span><span>View Call Logs</span></router-link>
                </li>
                <li v-if="userData.id==126">
                    <router-link to="/check-duplicate"><span class="las la-phone"></span><span>Duplicate Check</span></router-link>
                </li>
                <li v-if="userData.id==126">
                    <router-link to="/dnc-check"><span class="las la-phone"></span><span>DNC/Blocked List</span></router-link>
                </li>
                <li v-if="userData.id==126">
                    <router-link to="/affiliate-offers"><span class="las la-phone"></span><span>Affiliate Offers</span></router-link>
                </li>
                <li><a href="#" @click="onLogout($event)"><span class="las la-power-off"></span><span>Logout</span></a></li>
            </ul>
            <ul>

            </ul>
        </div>
    </div>
</div>
<div>
    <div class="main-content">
        <main>
            <section>
                <div class="header-wrapper">
                    <div class="header-title">
                        <h5><label for="menu-toggle" style="color:#ff118f; font-size:24px;"><span class="las la-bars"></span></label>Affiliates</h5>
                    </div>
                </div>
                <br />
            </section>
            <br />
            <!--<section>
                <div class="analytics">
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-file-invoice-dollar"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Mortgage Data Today</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.data.today[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-file-invoice-dollar"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Mortgage Data Yesterday</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.data.yesterday[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-file-invoice-dollar"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Mortgage Data Week</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.data.week[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-file-invoice-dollar"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Mortgage Data Month</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.data.month[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-file-invoice-dollar"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Mortgage Data All Time</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.data.all_time[0].count}}</h5>
                        </div>
                    </div>
                   
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Live Transfers Today</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.live_transfers.today[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Live Transfers Yesterday</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.live_transfers.yesterday[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Live Transfers Week</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.live_transfers.week[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Live Transfers Month</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.live_transfers.month[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Live Transfers All Time</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.live_transfers.all_time[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-file-invoice-dollar"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Debt Data Today</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.debt_data.today[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-file-invoice-dollar"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Debt Data Yesterday</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.debt_data.yesterday[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-file-invoice-dollar"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Debt Data Week</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.debt_data.week[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-file-invoice-dollar"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Debt Data Month</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.debt_data.month[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-file-invoice-dollar"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Debt Data All Time</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.debt_data.all_time[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Debt Calls Today</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.debt_click_to_call.today[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Debt Calls Yesterday</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.debt_click_to_call.yesterday[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Debt Calls Week</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.debt_click_to_call.week[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Debt Calls Month</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.debt_click_to_call.month[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Debt Calls All Time</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.debt_click_to_call.all_time[0].count}}</h5>
                        </div>
                    </div>                   
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Click Calls Today</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.click_to_call.today[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Click Calls Yesterday</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.click_to_call.yesterday[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Click Calls Week</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.click_to_call.week[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Click Calls Month</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.click_to_call.month[0].count}}</h5>
                        </div>
                    </div>
                    <div class="analytic">
                        <div class="analytic-icon"><i class="las la-phone"></i></div>
                        <div class="analytic-info">
                            <h4 class="makefontsmaller">Click Calls All Time</h4>
                            <h5 v-if="allData.length<=0"><loader/></h5>
                            <h5 v-else>{{allData.click_to_call.all_time[0].count}}</h5>
                        </div>
                    </div>
                </div>
            </section>-->
            <section class="hidemobile" :key="appkey">
                <header>
                    <div class="header-wrapper">
                        <div class="header-title">
                            <h5></h5>
                        </div>
                    </div>
                </header>

                <br />
                <table id="customers" :key="userkey">
                    <tr>
                        <th>id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Password</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    <tbody class="testme">
                        <tr v-for="r in getUsers" :key="r.vici_agent">
                            <td>{{r.id}}</td>
                            <td>{{r.name}}</td>
                            <td>{{r.email}}</td>
                            <td>{{getPassword(r.details)}}</td>
                            <td>{{getStatus(r.id)}}</td>
                            <td>
                                <a href="#" @click="setselectedSource($event, r)" v-tooltip="'View Details.'" style="color:green;"><i class="fa fa-eye"></i></a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <router-link :to="'/pricing/'+r.id+'/'+r.name" style="color:blue;"><i class="las la-money-check-alt" v-tooltip="'Set Pricing.'"></i></router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href="#" style="color:red;" @click="SetVerification($event,r.id)" v-tooltip="'Need Verification for Medicare Leads.'"><img src="@/assets/police-removebg-preview.png" class="police"></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href="#" style="color:red;" @click="SetBlock($event,r.id)"><i class="fa fa-ban" v-tooltip="'Block From Sending Medicare Leads.'"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href="#" style="color:green;" @click="RemoveBlock($event,r.id)" v-tooltip="'Remove Block/Verification.'"><i class="fa fa-check"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </main>
        <modelWindow v-if="showModel" @UserAdded="UserAdded" @CloseDialog="showModel=false;"></modelWindow>
        <DetailsWindow v-if="showModelDetails" :selected="selectedAffiliate" @CloseDialog="showModelDetails=false;"></DetailsWindow>

        <div id="snackbar">Link Copied</div>
    </div>
</div>
</template>

<script>
import 'vue3-date-time-picker/dist/main.css';
import Papa from 'papaparse';
import modelWindow from './modelWindow.vue';
import DetailsWindow from './DetailsWindow.vue';
import loader from './loader.vue';
import Swal from 'sweetalert2'

export default {
    name: 'admin-home',
    components: {

        modelWindow,
        DetailsWindow,
        loader
    },
    data() {
        return {
            appkey:1,
            mediStatus: [],
            showModel: false,
            totalVisits: 0,
            approved: 0,
            formVisits: 0,
            reportVisits: 0,
            conversion: 0,
            loading: true,
            date: null,
            graphKey: 1,
            visitData: [],
            shannon: 0,
            conversationData: [],
            sold: [],
            source: 'mortgageorganic',
            users: [],
            active_el: 1,
            name: '',
            selectedAffiliate: '',
            showMessage: true,
            userData: [],
            showModelDetails: false,
            selectedSource: '',
            allData: [],
            loaded: false,
            userkey:1,
        }
    },
    mounted() {},
    computed: {
        getUsers() {
            return this.users;
        },
        format() {
            return 'yyyy-MM-dd'
        },
        checkOrganic() {
            if (localStorage.getItem('source') == 'mortgageorganic') {
                return true;
            }
            return false;
        }
    },
    methods: {
        getStatus(id) {
            let index = this.mediStatus.findIndex(x => x.user_id === id);
            if (index != -1) {
                let temp = this.mediStatus[index];
                if (temp.u_type == 2) {
                    return "Lead Verification";
                } else {
                    return "Blocked";
                }
            } else {
                return "Active";
            }
        },
        getName(val) {
            try {
                let temp = JSON.parse(val);
                return temp.name;
            } catch (error) {
                console.log(error);
                return "not_Saved";
            }
        },
        getPassword(val) {
            try {
                let temp = JSON.parse(val);
                return temp.password;
            } catch (error) {
                return "not_Saved";
            }
        },
        setselectedSource(event, selectedAffiliate) {
            event.preventDefault();
            this.selectedAffiliate = selectedAffiliate;
            this.showModelDetails = true;
        },
        approveAffiliate(event, val, id) {
            let data = {
                "id": id,
                "val": val
            };
            this.axios.post("/admin/approve", data).then(response => {
                Swal.fire({
                    title: 'Success!',
                    text: "Affiliate approved.",
                    icon: 'success',

                });
                this.readUsers();
            });
        },
        UserAdded() {
            this.showModel = false;
            this.readUsers();
            var x = document.getElementById("snackbar");
            x.className = "show";
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 3000);
        },
        downloadCsv(event) {
            event.preventDefault();
            let yourDate = new Date()
            let filename = "Logs-" + yourDate.toISOString().split('T')[0] + ".csv";

            var csv = Papa.unparse(this.getLogData);
            var csvData = new Blob([csv], {
                type: 'text/csv;charset=utf-8;'
            });
            var csvURL = null;
            if (navigator.msSaveBlob) {
                csvURL = navigator.msSaveBlob(csvData, filename);
            } else {
                csvURL = window.URL.createObjectURL(csvData);
            }
            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', filename);
            tempLink.click();
        },
        onLogout(event) {
            event.preventDefault();
            this.$emit('onLogout');
        },
        copyLink(event, link) {
            event.preventDefault();
            const el = document.createElement('textarea');
            el.value = link + "?utm_source=" + localStorage.getItem('source');
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            var x = document.getElementById("snackbar");
            x.className = "show";
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 3000);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
        },
        getVisitConversion(id) {
            let k = this.conversationData.filter(x => x.page_id == id);
            if (k.length > 0) {
                return k[0].visits;
            } else {
                return 0;
            }
        },
        getVisit(id) {
            let k = this.visitData.filter(x => x.page_id == id);
            if (k.length > 0) {
                return k[0].visits;
            } else {
                return 0;
            }

        },
        convert(str) {
            var date = new Date(str),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        },
        updateDate() {
            let vm = this;
            localStorage.setItem('start', this.convert(this.date[0]));
            localStorage.setItem('end', this.convert(this.date[1]));
            this.read();
        },

        getNameOfMonth(dt) {
            let mlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return mlist[dt.getMonth()];
        },
        readSource(event, src, name, approved, id) {
            this.name = name;
            this.source = src;
            this.approved = approved;
            this.selectedId = id;
            this.showMessage = false;
            this.read();
        },
        RemoveBlock(event, id){
            event.preventDefault();
            this.axios.post("https://cdn.tycoonmach.net/api/medicare/unblock", {
                id: id
            }).then(response => {
                this.mediStatus = response.data.medi;
                this.appkey++;
            })
        },
        SetVerification(event, id){
            event.preventDefault();
            this.axios.post("https://cdn.tycoonmach.net/api/medicare/block", {
                id: id,
                typ: 2
            }).then(response => {
                this.mediStatus = response.data.medi;
                this.read();
                this.appkey++;
            })
        },
        SetBlock(event, id){
            event.preventDefault();
            this.axios.post("https://cdn.tycoonmach.net/api/medicare/block", {
                id: id,
                typ: 1
            }).then(response => {
                this.mediStatus = response.data.medi;
                this.read();
                this.appkey++;
            })
        },
        read() {
            this.loading = true;
            this.axios.post("https://arlo.tycoonmach.net/api/medicare/unblock", {
                id: 0
            }).then(response => {
                this.mediStatus = response.data.medi;
                let temp = this.$store.getters.getaffiliate;
                if (temp <= 0) {
                    this.readUsers();
                } else {
                    if (this.userData.id == 148) {
                        temp.forEach(element => {
                            if (element.aff_type == 2) {
                                this.users.push(element);
                            }
                        });
                    } else {
                        this.users = temp;
                    }
                }
            })
        },
        readUsersAgain($event) {
            event.preventDefault();
            this.readUsers();
        },
        readUsers() {
            this.axios.get("/dash/users").then(response => {
                this.users = response.data.users;
                this.$store.dispatch("setAffiliate", response.data.users);
                this.userkey++;
            });
        }
    },
    created() {
        this.date = [localStorage.getItem('start'), localStorage.getItem('end')];
        this.userData = this.$store.getters.getuserData;
        this.read();
    }
}
</script>

<style scoped>
img {
    width: 100%;
    height: auto;
}

.m_title:first-letter {
    text-transform: uppercase
}

#menu-toggle {
    display: none;
}

#menu-toggle:checked~.sidebar {
    left: -345px;
}

#menu-toggle:checked~.main-content {
    margin-left: 0;
    width: 100vw;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    display: none;
    background-color: rgba(255, 255, 255, 0.5);
}

/* helper */
.text-danger {
    color: red;
}

.text-success {
    color: #2ec3a3;
}

.text-main {
    color: var(--color-main);
}

/*
Sidebar
*/
.sidebar {
    width: 345px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 1rem 1.2rem;
    transition: left 300ms;
}

.sidebar-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: var(--shadow);
    padding: 1.2rem;
    overflow-y: auto;
}

.sidebar-container::-webkit-scrollbar {
    width: 5px;
}

.sidebar-container::-webkit-scrollbar-track {
    box-shadow: var(--shadow);
}

.sidebar-container::-webkit-scrollbar-thumb {
    background-color: var(--main-accent);
    outline: 1px solid #ccc;
    border-radius: 2px;
}

.brand {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand h3 {
    color: #444;
    font-size: 3rem;
}

.brand h3 span {
    color: var(--color-main);
    font-size: 2.5rem;
    display: inline-block;
    margin-right: 0.5rem;
}

.sidebar-avatar {
    display: grid;
    grid-template-columns: 70px auto;
    align-items: center;
    border: 2px solid var(--main-accent);
    padding: 0.1rem 0.7rem;
    border-radius: 7px;
    margin: 2rem 0rem;
}

.sidebar-avatar img {
    width: 40px;
    border-radius: 10px;
    margin: 5px 0;
}

.avatar-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-menu li {
    margin-bottom: 2rem;
}

.sidebar-menu a {
    color: var(--main-text);
    display: block;
    padding: 0.7rem 0;
}

.sidebar-menu a.active {
    background-color: var(--main-accent);
    border-radius: 7px;
    padding: 0.8rem;
}

.sidebar-menu a span:first-child {
    display: inline-block;
    margin-right: 0.8rem;
    font-size: 1.5rem;
    color: var(--color-main);
}

.sidebar-card {
    background-color: var(--main-accent);
    padding: 1rem;
    margin-top: 2rem;
    box-shadow: var(--shadow);
    text-align: center;
    border-radius: 7px;
}

.side-card-icon span {
    font-size: 8rem;
    color: var(--color-main);
    display: inline-block;
}

.side-card-icon {
    margin-bottom: 0.8rem;
}

.side-card-icon+div {
    margin-bottom: 1rem;
}

.side-card-icon+div p {
    font-size: 0.8rem;
    color: #555;
}

.btn {
    padding: 0.7rem 1rem;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.btn span {
    font-size: 1.2rem;
    display: inline-block;
    margin-right: 0.7rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-main {
    background-color: var(--color-main);
    color: #fff;
}

/* main */
.main-content {
    margin-left: 345px;
    width: calc(100vw - 345px);
    padding: 1rem 2rem 1.2rem 1.2rem;
    transition: margin-left 300ms;
}

/* Header */
header {
    display: flex;
    padding-top: 0.5rem;
    justify-content: space-between;
}

.header-wrapper {
    display: flex;
}

.header-wrapper label {
    display: inline-block;
    color: var(--color-main);
    margin-right: 2rem;
    font-size: 1.8rem;
}

.header-wrapper label span:hover {
    cursor: pointer;
}

.header-title h1 {
    color: var(--main-text);
    font-weight: 600;
}

.header-title p {
    color: #666;
    font-size: 0.9rem;
}

.header-title p span {
    color: red;
    font-size: 1.2rem;
    display: inline-block;
    margin-left: 0.5rem;
}

.header-action button {
    padding: 0.85rem 2rem;
    font-size: 1rem;
}

main {
    padding: 2.5rem 0;
}

.analytics {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2.5rem;
    margin-bottom: 3rem;
}

.analytic {
    box-shadow: var(--shadow);
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    padding-left: 2rem;
}

.analytic-info h4 {
    font-weight: 400;
    color: #555;
    font-size: 0.98rem;
}

.analytic-info h1 {
    color: var(--main-text);
    font-weight: 600;
}

.analytic-info h1 small {
    font-size: 0.8rem;
    font-weight: 700;
}

.analytic-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 0.8rem;
}

.section-head {
    font-size: 1.4rem;
    color: var(--main);
    font-weight: 600;
    margin-bottom: 1rem;
}

.block-grid {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 30% auto;
}

.rev-content {
    background-color: #fff;
    box-shadow: var(--shadow);
    border-radius: 15px;
    padding: 1rem 2rem;
    text-align: center;
}

.rev-content img {
    width: 180px;
    margin: 1.5rem 0;
    border-radius: 50%;
}

.rev-info {
    margin-bottom: 1rem;
}

.rev-info h3,
.rev-sum h4 {
    font-weight: 600;
    color: var(--main);
}

.rev-info h1,
.rev-sum h2 {
    font-weight: 400;
    margin-top: 0.4rem;
    color: #555;
}

.rev-info h1 small {
    font-size: 1rem;
}

.rev-sum {
    background-color: var(--main-accent);
    border: 1px solid var(--color-main);
    padding: 1rem;
    border-radius: 10px;
}

.graph-content {
    border-radius: 15px;
    box-shadow: var(--shadow);
    padding: 1rem;
    background-color: #fff;
}

.graph-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

.icon {
    height: 40px;
    width: 40px;
    border-radius: 7px;
    background-color: #eee;
    display: grid;
    place-items: center;
    font-size: 1.2rem;
    margin-right: 0.8rem;
}

.graph-head select {
    outline: none;
    background-color: #eee;
    height: 35px;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    border: none;
}

.overlay label {
    display: block;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1224px) {
    .sidebar {
        left: -345px;
        z-index: 30;
    }

    .main-content {
        width: 100vw;
        margin-left: 0;
    }

    #menu-toggle:checked~.sidebar {
        left: 0;
    }

    #menu-toggle:checked~.overlay {
        display: block;
        text-align: right;
    }
}

@media only screen and (max-width: 860px) {
    .analytics {
        grid-template-columns: repeat(2, 1fr);
    }

    .block-grid {
        grid-template-columns: 100%;
    }

    .revenue-card {
        order: 2;
    }
}

@media only screen and (max-width: 580px) {
    .analytics {
        grid-template-columns: 100%;
    }
}

@media only screen and (max-width: 500px) {

    .header,
    header-wrapper {
        align-items: center;
    }

    .header-title h1 {
        font-size: 1.2em;
    }

    .header-title p {
        display: none;
    }
}

.makefontsmaller {
    font-size: 12px !important;
}

.police {
    height: 20px !important;
    width: 20px !important;
}
</style>
