import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/HomeMain.vue";
import SetPricing from "@/components/SetPricing.vue";
import CreateCampaigns from "@/components/CreateCampaigns.vue";
import ViewStats from "@/components/ViewStats.vue";
import Login from "@/components/Login.vue";
import ConversationNFills from "@/components/ConversationNFills.vue";
import callLogs from "@/components/callLogs.vue";
import DuplicateCheck from "@/components/DuplicateCheck.vue";
import DncCheck from  "@/components/DncCheck.vue";
import AffiliateLogs from  "@/components/AffiliateLogs.vue";
import Offers from  "@/components/offers.vue";
import adminOffers from  "@/components/adminOffers.vue";
import store from '../store'
import AdminConversion from '@/components/AdminConversion.vue'
import medicareCorrection from '@/components/medicareCorrection.vue'
import DupeCheck from '@/components/DupeCheck.vue'

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/medi-correction",
    name: "medicareCorrection",
    component: medicareCorrection,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/dupecheck",
    name: "DupeCheck",
    component: DupeCheck,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/call-logs",
    name: "calllogs",
    component: callLogs,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/offers",
    name: "Offers",
    component: Offers,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/affiliate-logs",
    name: "AffiliateLogs",
    component: AffiliateLogs,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/check-duplicate",
    name: "DuplicateCheck",
    component: DuplicateCheck,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/dnc-check",
    name: "DncCheck",
    component: DncCheck,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/connlinks",
    name: "ConversationNFills",
    component: ConversationNFills,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/conver",
    name: "AdminConversion",
    component: AdminConversion,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/pricing/:id/:name",
    name: "SetPricing",
    component: SetPricing,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/stats/:id/:name/:src",
    name: "ViewStats",
    component: ViewStats,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/campaigns",
    name: "CreateCampaigns",
    component: CreateCampaigns,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/affiliate-offers",
    name: "adminOffers",
    component: adminOffers,
    meta: {
      requiresAuth: true,
    }
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.fullPath=='/login'){
    next();
  }else{
    if (store.getters.authToken == null) {
      next({
        path: '/login'
      })
    } else {
      next();
    }
  }
  
})

export default router;