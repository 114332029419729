<template>
  <router-view @DoLogout="DoLogout"></router-view>
</template>

<script>

export default {
    name: 'App',
    
    data() {
        return {
            sKey: 1
        }
    },
    mounted(){
        //checkLoginState();
    },
    watch:{
      isLoggedin: function (newVal, oldVal) {
            if(newVal){
               this.checkLoginState();
            }else{
            }
        }
    },
    computed: {
        isLoggedin(){
            return this.$store.getters.isAuthenticated;
        },
    },
    methods: {
        DoLogout() {
            this.$store.dispatch("doLogout", {
                    token: this.$store.getters.authToken
                }).then((response) => {
                    console.log(response);
                })
                .catch(err => {
                    console.log(err);
                    
                });

        },
        checkLoginState(){
            if(this.$store.getters.isAuthenticated){
                this.$router.replace("/");
            }else{
                this.$router.replace("/login");
            }
        },
        setSource(source) {
            localStorage.setItem('source', source);
            window.location.reload();
        }
    },
    created() {
        if (localStorage.getItem('start') == null || localStorage.getItem('start') == '') {
            var date = new Date();
            var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
            localStorage.setItem('end', dateString);
            date.setDate(date.getDate() - 5);
            var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
            localStorage.setItem('start', dateString);
        }
    }
}
</script>

<style>

</style>
