<template>
<div class="modal" style="padding-top:10%">
    <div class="modal-content" style="background-color:transparent;border:none;">
        <div>
            <form id="contact" @submit.prevent="submitForm">
                <span @click="CloseDialog" style="float:right;font-size:20px;cursor:pointer;">&times;</span>
                <h3>Stats for {{selected.name}} </h3>
                <div class="container" style="diaplay:flex;justify-contents:left;font-size:14px;" v-if="!loading">
                    <div class="row" v-if="selected.aff_type!=2">
                        <div class="col" >
                            <div class="form-group">
                                <label for="exampleInputEmail1">Data Leads Today : {{data.data.today[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Data Leads Yesterday : {{data.data.yesterday[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Data Leads This Month : {{data.data.month[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Data Leads All Times : {{data.data.all_time[0].count}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Click To Call Today : {{data.click_to_call.today[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Click To Call Yesterday : {{data.click_to_call.yesterday[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Click To Call This Month : {{data.click_to_call.month[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Click To Call All Time : {{data.click_to_call.all_time[0].count}}</label>
                            </div>
                        </div>
                    </div>
                    <hr v-if="selected.aff_type!=2" />
                    <div class="row" v-if="selected.aff_type!=2">
                        <div class="col">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Debt Click To Call Today : {{data.debt_click_to_call.today[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Debt Click To Call Yesterday : {{data.debt_click_to_call.yesterday[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Debt Click To Call This Month : {{data.debt_click_to_call.month[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Debt Click To Call All Time : {{data.debt_click_to_call.all_time[0].count}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Debt Data Today : {{data.debt_data.today[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Debt Data Yesterday : {{data.debt_data.yesterday[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Debt Data This Month : {{data.debt_data.month[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Debt Data All Time : {{data.debt_data.all_time[0].count}}</label>
                            </div>
                        </div>
                    </div>
                    <hr v-if="selected.aff_type!=2" />
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Live Transfers Today : {{data.live_transfers.today[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Live Transfers Yesterday : {{data.live_transfers.yesterday[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Live Transfers This Month : {{data.live_transfers.month[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Live Transfers All Time : {{data.live_transfers.all_time[0].count}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Returns Today : {{data.return_data.today[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Returns Yesterday : {{data.return_data.yesterday[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Returns This Month : {{data.return_data.month[0].count}}</label>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Returns All Time : {{data.return_data.all_time[0].count}}</label>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                     <div class="row">
                        <router-link :to="'/stats/'+selected.id+'/'+selected.name+'/'+selected.vici_agent" class="btn btn-outline-primary">View Details By Date</router-link>
                    </div>
                </div>
                <div class="container" style="diaplay:flex;justify-contents:left;font-size:14px;" v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" fill="none" stroke="#1d3f72" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </circle>
                    </svg>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ["selected"],
    data() {
        return {
            data: '',
            loading: true,
        }
    },
    methods: {
        CloseDialog() {
            this.$emit("CloseDialog");
        },
        read() {
            this.loading= true;
            let data = {
                source: this.selected.vici_agent
            };
            this.axios.post("https://arlo.tycoonmach.net/api/lead/details", data).then(response => {
                this.data = response.data;
                this.loading= false;
            })
        }
    },
    created() {
        this.read();
    }
}
</script>

<style scoped>
.selectbox {
    width: 100%;
    border: 1px solid #ccc;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
}

.modal {
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    padding-top: 2px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    max-width: 800px;
    border-radius: 0.3rem;
    justify-content: center;
    font-size: 11px;
    position: relative;
}

#contact {
    background: #F9F9F9;
    padding: 25px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

#contact h3 {
    display: block;
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 10px;
}

#contact h4 {
    margin: 5px 0 15px;
    display: block;
    font-size: 13px;
    font-weight: 400;
}

fieldset {
    border: medium none !important;
    margin: 0 0 10px;
    min-width: 100%;
    padding: 0;
    width: 100%;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="password"],
#contact textarea {
    width: 100%;
    border: 1px solid #ccc;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
}

#contact input[type="text"]:hover,
#contact input[type="email"]:hover,
#contact input[type="tel"]:hover,
#contact input[type="url"]:hover,
#contact textarea:hover {
    -webkit-transition: border-color 0.3s ease-in-out;
    -moz-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    border: 1px solid #aaa;
}

#contact textarea {
    height: 100px;
    max-width: 100%;
    resize: none;
}

#contact button[type="submit"] {
    cursor: pointer;
    width: 100%;
    border: none;
    background: #0099ff;
    color: #FFF;
    margin: 0 0 5px;
    padding: 10px;
    font-size: 15px;
}

#contact button[type="submit"]:hover {
    background: #43A047;
    -webkit-transition: background 0.3s ease-in-out;
    -moz-transition: background 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.copyright {
    text-align: center;
}

#contact input:focus,
#contact textarea:focus {
    outline: 0;
    border: 1px solid #aaa;
}

::-webkit-input-placeholder {
    color: #888;
}

:-moz-placeholder {
    color: #888;
}

::-moz-placeholder {
    color: #888;
}

:-ms-input-placeholder {
    color: #888;
}
</style>
