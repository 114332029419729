<template>
<div v-bind:class="{ 'hideDiv': !loading}">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#1d3f72" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
    </svg>
</div>
<modelWindow v-if="showPostbackModel" @CloseDialog="showPostbackModel=false;" @PostbackSaved="PostbackSaved"></modelWindow>
<div class="sidebar">
    <div class="sidebar-container">
        <div class="brand">
            <img src="@/assets/logonew.png" style="width:100px">
        </div>
        <div class="sidebar-avatar">
            <div>
                <img src="@/assets/user.jpeg" alt="avatar">
            </div>
            <div class="avatar-info">
                <div class="avatar-text">
                    <h6>{{userData.name}}</h6>
                </div>
            </div>
        </div>
        <div class="sidebar-menu">
            <ul>
                <li><a href="#" @click="onLogout($event)"><span class="las la-power-off"></span><span>Logout</span></a></li>
            </ul>
        </div>
    </div>
</div>
<div class="main-content" style="width:80%" v-bind:class="{ 'hideDiv': loading}">
    <main>
        <header>
            <div class="header-wrapper">
                <div class="header-title">
                    <h5>Free Covid Test Campaign</h5>
                    <div id="wrapper">
                        <div id="div1">
                            <Datepicker v-model="date" range multiCalendars @blur="updateDate" :format="format" />
                        </div>
                        <div id="div2" @click="read()"><i class="fa fa-search" style="font-size:24px"></i></div>
                    </div>

                </div>
            </div>
            <div class="header-action">
                <!--<div class="analytic-icon"><a href="" @click="downloadCsv($event)"><img src="@/assets/downloading-updates.png"></a></div>-->
            </div>
        </header>
        <br />
        <section>
            <div class="analytics">
                <div class="analytic">
                    <div class="analytic-icon"><i class="fa fa-phone" style="font-size:16px"></i></div>
                    <div class="analytic-info">
                        <h4>Submissions</h4>
                        <h5>{{totalVisits}}</h5>
                    </div>
                </div>

                <div class="analytic">
                    <div class="analytic-icon"><i class="fa fa-microphone" style="font-size:16px"></i></div>
                    <div class="analytic-info">
                        <h4>Approved</h4>
                        <h5>{{totalRecordings}}</h5>
                    </div>
                </div>
            </div>
        </section>
        <br /><br />
        <section>
            <header>
                <div class="header-wrapper">
                    <div class="header-title">
                        <h5>Use this Webform URL is:</h5>
                    </div>
                </div>
            </header>

            <br />
            <p><a :href="getLink" target="_blank">{{getLink}}</a></p>
            <br>
            <p><a :href="getLink3" target="_blank">{{getLink3}}</a></p><br/>
            <p><b>If you want to pre-fill name and phone you can use the link examples below:</b><br/>
                {{getLink}}&first_name={customer_fname}&amp;last_name={customer_lname}&amp;phone={customer_phone}<br/>    
                {{getLink2}}&first_name={customer_fname}&amp;last_name={customer_lname}&amp;phone={customer_phone} <br>
                <span style="color:red;">!! Please be sure to remove the curly brackets and replace with your info !!</span>
                </p>
        </section>

        <section v-if="additionaloffers.length>0">
            <header>
                <div class="header-wrapper">
                    <div class="header-title">
                        <h5>Your DID:</h5>
                    </div>
                </div>
            </header>

            <br />
            <p v-for="a in additionaloffers" :key="a.link">{{a.link}} <br/></p>
            <br>
            
        </section>

    </main>

</div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import Papa from 'papaparse';
import conversion from './conversion.vue';
import clicksconv from './clicksconv.vue';
import modelWindow from './Postback.vue';
import Swal from 'sweetalert2'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    name: 'home',
    components: {
        Datepicker,
        conversion,
        clicksconv,
        modelWindow,
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            showPostbackModel: false,
            totalVisits: 0,
            totalRecordings: 0,
            tempName: "Upload CSV",
            formVisits: 0,
            reportVisits: 0,
            conversion: 0,
            loading: true,
            date: null,
            graphKey: 1,
            visitData: [],
            shannon: 0,
            conversationData: [],
            sold: [],
            qConver: 0,
            active_el: 1,
            active_Page: 1,
            userData: [],
            logData: [],
            file: null,
            additionaloffers:'',
        }
    },
    mounted() {},
    computed: {
        format() {
            return 'yyyy-MM-dd'
        },
        getLink2(){
            return "https://freerapidcovidkits.com/pre?utm_source=" + this.userData.id;
        },
        getLink3(){
            return "https://freerapidcovidkits.com/?utm_source=" + this.userData.id;
        },
        getLink() {

            return "https://apply.freerapidcovidkits.com?utm_source=" + this.userData.id;
        },
    },
    methods: {
        
        PostbackSaved() {
            this.showPostbackModel = false;
            Swal.fire({
                title: 'Success!',
                text: "URL Saved.",
                icon: 'success',

            });
        },

        onLogout(event) {
            event.preventDefault();
            this.$emit('onLogout');
        },
        
        convert(str) {
            var date = new Date(str),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        },
        updateDate() {
            let vm = this;
            localStorage.setItem('start', this.convert(this.date[0]));
            localStorage.setItem('end', this.convert(this.date[1]));
        },
        getNameOfMonth(dt) {
            let mlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return mlist[dt.getMonth()];
        },
        read() {
            let vm = this;
            vm.loading = true;
            vm.totalVisits = 0;
            let data = {
                startDate: localStorage.getItem('start'),
                endDate: localStorage.getItem('end'),
            }
            vm.axios.post("https://pages.tycoonmach.net/api/medicare/" + this.userData.id, data).then(response => {
                this.totalVisits = response.data.total;
                this.totalRecordings = response.data.recordings;
                this.loading = false;
            })
            this.loading = false;
        },
        additionalOffer(){
            let vm = this;
           
            
            vm.axios.get("/dash/offers", ).then(response => {
                this.additionaloffers = response.data.offers.pricing;
                
            })
        }
    },
    created() {
        this.date = [localStorage.getItem('start'), localStorage.getItem('end')];
        this.userData = this.$store.getters.getuserData;
        this.read();
        this.additionalOffer();
    }
}
</script>

<style>
#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0099ff;
    color: white;
}

.testme {
    height: 100px;
}

@media screen and (max-width: 600px) {

    .hidemobile {

        display: none;

    }

}

img {
    width: 100%;
    height: auto;
}

.m_title:first-letter {
    text-transform: uppercase
}

#menu-toggle {
    display: none;
}

#menu-toggle:checked~.sidebar {
    left: -345px;
}

#menu-toggle:checked~.main-content {
    margin-left: 0;
    width: 100vw;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    display: none;
    background-color: rgba(255, 255, 255, 0.5);
}

/* helper */
.text-danger {
    color: red;
}

.text-success {
    color: #2ec3a3;
}

.text-main {
    color: var(--color-main);
}

/*
Sidebar
*/
.sidebar {
    width: 20%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 1rem 1.2rem;
    transition: left 300ms;
}

.sidebar-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: var(--shadow);
    padding: 1.2rem;
    overflow-y: auto;
}

.sidebar-container::-webkit-scrollbar {
    width: 5px;
}

.sidebar-container::-webkit-scrollbar-track {
    box-shadow: var(--shadow);
}

.sidebar-container::-webkit-scrollbar-thumb {
    background-color: var(--main-accent);
    outline: 1px solid #ccc;
    border-radius: 2px;
}

.brand {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand h3 {
    color: #444;
    font-size: 3rem;
}

.brand h3 span {
    color: var(--color-main);
    font-size: 2.5rem;
    display: inline-block;
    margin-right: 0.5rem;
}

.sidebar-avatar {
    display: grid;
    grid-template-columns: 70px auto;
    align-items: center;
    border: 2px solid var(--main-accent);
    padding: 0.1rem 0.7rem;
    border-radius: 7px;
    margin: 2rem 0rem;
}

.sidebar-avatar img {
    width: 40px;
    border-radius: 10px;
    margin: 5px 0;
}

.avatar-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-menu li {
    margin-bottom: 2rem;
}

.sidebar-menu a {
    color: var(--main-text);
    display: block;
    padding: 0.7rem 0;
}

.sidebar-menu a.active {
    background-color: var(--main-accent);
    border-radius: 7px;
    padding: 0.8rem;
}

.sidebar-menu a span:first-child {
    display: inline-block;
    margin-right: 0.8rem;
    font-size: 1.5rem;
    color: var(--color-main);
}

.sidebar-card {
    background-color: var(--main-accent);
    padding: 1rem;
    margin-top: 2rem;
    box-shadow: var(--shadow);
    text-align: center;
    border-radius: 7px;
}

.side-card-icon span {
    font-size: 8rem;
    color: var(--color-main);
    display: inline-block;
}

.side-card-icon {
    margin-bottom: 0.8rem;
}

.side-card-icon+div {
    margin-bottom: 1rem;
}

.side-card-icon+div p {
    font-size: 0.8rem;
    color: #555;
}

.btn {
    padding: 0.7rem 1rem;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.btn span {
    font-size: 1.2rem;
    display: inline-block;
    margin-right: 0.7rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-main {
    background-color: var(--color-main);
    color: #fff;
}

/* main */
.main-content {
    width:100%;
    margin-left: 20%;
    width: calc(100vw - 345px);
    padding: 1rem 2rem 1.2rem 1.2rem;
    transition: margin-left 300ms;
}

/* Header */
header {
    display: flex;
    padding-top: 0.5rem;
    justify-content: space-between;
}

.header-wrapper {
    display: flex;
}

.header-wrapper label {
    display: inline-block;
    color: var(--color-main);
    margin-right: 2rem;
    font-size: 1.8rem;
}

.header-wrapper label span:hover {
    cursor: pointer;
}

.header-title h1 {
    color: var(--main-text);
    font-weight: 600;
}

.header-title p {
    color: #666;
    font-size: 0.9rem;
}

.header-title p span {
    color: red;
    font-size: 1.2rem;
    display: inline-block;
    margin-left: 0.5rem;
}

.header-action button {
    padding: 0.85rem 2rem;
    font-size: 1rem;
}

main {
    padding: 2.5rem 0;
}

.analytics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2.5rem;
    margin-bottom: 3rem;
}

.analytic {
    box-shadow: var(--shadow);
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    padding-left: 2rem;
}

.analytic-info h4 {
    font-weight: 400;
    color: #555;
    font-size: 0.98rem;
}

.analytic-info h1 {
    color: var(--main-text);
    font-weight: 600;
}

.analytic-info h1 small {
    font-size: 0.8rem;
    font-weight: 700;
}

.analytic-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 0.8rem;
}

.section-head {
    font-size: 1.4rem;
    color: var(--main);
    font-weight: 600;
    margin-bottom: 1rem;
}

.block-grid {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 30% auto;
}

.rev-content {
    background-color: #fff;
    box-shadow: var(--shadow);
    border-radius: 15px;
    padding: 1rem 2rem;
    text-align: center;
}

.rev-content img {
    width: 180px;
    margin: 1.5rem 0;
    border-radius: 50%;
}

.rev-info {
    margin-bottom: 1rem;
}

.rev-info h3,
.rev-sum h4 {
    font-weight: 600;
    color: var(--main);
}

.rev-info h1,
.rev-sum h2 {
    font-weight: 400;
    margin-top: 0.4rem;
    color: #555;
}

.rev-info h1 small {
    font-size: 1rem;
}

.rev-sum {
    background-color: var(--main-accent);
    border: 1px solid var(--color-main);
    padding: 1rem;
    border-radius: 10px;
}

.graph-content {
    border-radius: 15px;
    box-shadow: var(--shadow);
    padding: 1rem;
    background-color: #fff;
}

.graph-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

.icon {
    height: 40px;
    width: 40px;
    border-radius: 7px;
    background-color: #eee;
    display: grid;
    place-items: center;
    font-size: 1.2rem;
    margin-right: 0.8rem;
}

.graph-head select {
    outline: none;
    background-color: #eee;
    height: 35px;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    border: none;
}

.overlay label {
    display: block;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1224px) {
    .sidebar {
        left: -345px;
        z-index: 30;
    }

    .main-content {
        width: 100vw;
        margin-left: 0;
    }

    #menu-toggle:checked~.sidebar {
        left: 0;
    }

    #menu-toggle:checked~.overlay {
        display: block;
        text-align: right;
    }
}

@media only screen and (max-width: 860px) {
    .analytics {
        grid-template-columns: repeat(2, 1fr);
    }

    .block-grid {
        grid-template-columns: 100%;
    }

    .revenue-card {
        order: 2;
    }
}

@media only screen and (max-width: 580px) {
    .analytics {
        grid-template-columns: 100%;
    }
}

@media only screen and (max-width: 500px) {

    .header,
    header-wrapper {
        align-items: center;
    }

    .header-title h1 {
        font-size: 1.2em;
    }

    .header-title p {
        display: none;
    }
}

.image-upload-wrap {
    margin-top: 20px;
    border: 4px dashed #0099ff;
    position: relative;
    height: 150px;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.drag-text {
    text-align: center;
    overflow: hidden;
}

#wrapper {
    width: 740px;
}

#div1 {
    display: inline-block;
    width: 320px;
}

#div2 {
    display: inline-block;
    padding-left: 25px;
    width: 160px;
}

.drag-text {
    text-align: center;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
</style>
