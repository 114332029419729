<template>
<div v-if="isAdmin">
    <admin-home @onLogout="onLogout"></admin-home>
</div>
<div v-else>
    <HomeMedicare @onLogout="onLogout" v-if="userData.is_admin==8"></HomeMedicare>
    <HomeMedicareAds @onLogout="onLogout" v-else-if="userData.is_admin==9"></HomeMedicareAds>
    <home @onLogout="onLogout" v-else></home>
</div>
</template>

<script>
import Home from './Home.vue';
import HomeData from './HomeData.vue';
import AdminHome from './AdminHome.vue';
import HomeDebt from './HomeDebt.vue';
import HomeMedicare from './HomeMedicare.vue';
import HomeMedicareAds from './HomeMedicareAds.vue';
export default {
    components: {
        Home,
        AdminHome,
        HomeData,
        HomeDebt,
        HomeMedicare,
        HomeMedicareAds
    },
    data() {
        return {
            sKey: 1
        }
    },
    methods: {
        onLogout() {

            this.$emit('DoLogout');
        },
    },
    computed: {
        isCallCenter() {
            if (this.$store.getters.getuserData.aff_type == 2) {
                return true;
            } else {
                return false;
            }
        },
        isDebtCallCenter() {
            if (this.$store.getters.getuserData.aff_type == 4) {
                return true;
            } else {
                return false;
            }
        },
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        userData() {
            return this.$store.getters.getuserData;
        },
    },

    created() {

    }
}
</script>

<style>

</style>
