<template>
<div class="backgroudpage">
    <div class="container">
        <div class="top-header" style="padding-bottom:18px;">
            <div class=""><img src="@/assets/logonew.png" style="width:120px"></div>

        </div>
        <form @submit.prevent="onSubmit">

            <div class="user" style="padding:8px;">
                <label class="mylabel">Email</label>
                <input type="email" v-model="email" placeholder="Enter your email" />
            </div>
            <div class="pass" style="padding:8px;">
                <label class="mylabel">Password</label>
                <input type="password" v-model="password" placeholder="Enter your password" />
            </div>
            <p style="font-size:12px ; font-weight:normal;" v-if="err">
                <span class="help-block" style="color:red;">
                    <strong>{{ message }}</strong>
                </span>
            </p>
            <div class="btn">
                <button type="submit" style="min-width: 100px !important;" :disabled="submitted">
                    <span v-if="submitted">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                            </circle>
                        </svg>
                    </span>
                    <span style="font-size:14px" v-else>Sign In</span>
                </button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    props: ['siteSettings', 'imageAccess'],
    data() {
        return {
            email: "",
            password: "",
            submitted: false,
            message: '',
            err: false,
        };
    },
    methods: {
        onSubmit() {
            console.log("here");
            this.submitted = true;
            this.err = false;
            this.$store.dispatch("doLogin", {
                    username: this.email,
                    password: this.password
                }).then((response) => {

                })
                .catch(err => {
                    this.submitted = false;
                    this.message = err.message;
                    this.err = true;
                });
        }
    },
    created() {
        if (this.$store.getters.isAuthenticated) {
            this.$router.replace("/");
        }
    }
};
</script>

<style scoped>
.backgroudpage :before {
    content: "";
    position: absolute;
    z-index: -1;
    background: -webkit-gradient(linear, left top, right bottom, from(#3898EC), to(#be52e0));
    background: linear-gradient(to bottom right, #3898EC, #be52e0);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.container {
    width: 400px;
    height: 400px;
    background-color: #fff;
    margin: 150px auto;
    border-radius: 11px;
    padding: 40px 50px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* top-header */
.top-header {
    text-align: center;
    color: #495d76;
    justify-content: center;
}

.top-header p {
    color: #cecfd3;
    font-size: 13px;
    text-align: center;
    margin-top: 5px;

}

.mylabel {
    font-size: 12px;
    color: #0099ff;
}

/* top-header */
/* form */
.user input,
.pass input {
    width: 100%;
    height: 35px;
    border: none;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    outline: none;
    padding: 0 10px;
}

::placeholder {
    color: #9ca5b4;
    font-size: 12px;
}

.user i,
.pass i {
    position: relative;
    top: 31px;
    right: -10px;
    color: #3981ed;
}

/* form */
/* button */
.btn {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.btn button {
    width: 100%;
    border: none;
    height: 35px;
    background-color: #3898EC;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
}

/* button */
p.last {
    text-align: center;
    position: relative;
    bottom: 120px;
    font-size: 12px;
}
</style>
