<template>
<div class="modal" v-if="loading" style="padding-top:10%">
    <div class="modal-content" style="background-color:transparent;border:none;">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="#1d3f72" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
            </svg>
            <br />
            <span class="btnsload__text">Please wait</span>
        </div>
    </div>
</div>
<div class="sidebar">
    <div class="sidebar-container">
        <div class="brand">
            <img src="@/assets/logonew.png" style="width:100px">
        </div>
        <div class="sidebar-avatar">
            <div>
                <img src="@/assets/user.jpeg" alt="avatar">
            </div>
            <div class="avatar-info">
                <div class="avatar-text">
                    <h6>{{userData.name}}</h6>
                </div>
            </div>
        </div>
        <div class="sidebar-menu">
            <ul>
                <li><a href="/"><span class="fa fa-home"></span><span>Home</span></a></li>
                <li><a href="/medi-correction"><span class="fa fa-users"></span><span>Resubmit Leads</span></a></li>
                <li><a href="/dupecheck"><span class="fa fa-check"></span><span>Duplicate Check</span></a></li>
            </ul>
        </div>
    </div>
</div>
<div class="" style="width:70%;margin-left:25%">
    <main>
        <header>
            <div class="header-wrapper">
                <div class="header-title">
                    <h5>Duplicate Check On MediCare ID</h5>
                </div>
            </div>

        </header>
        <br />
        <section>
            <p style="color:red;">
                !!IMPORTANT PLEASE READ. DO NOT ENTER ANY TICKET FOR THIS!<br />
                <br />
                1. When uploading your list make sure the file has header medi_num (header in small cases).<br />
                2. It will take long if you have a Slow Computer. Make sure to close all Chrome Tabs to free RAM on your computer.<br />
                3. This suppression list is updated every 5 minutes.<br />
                4. We do not accept duplicates based upon Medicare number. Some people have multiple phone numbers which will lead to duplicates of you only suppress based on phone number.<br />
                5. Please remove any spaces or any other special characters. And make the Medi # to upper case before checking duplicates.<br />
            </p>
        </section>
        <br /><br />
        <section>
            <header>
                <div class="header-wrapper">
                    <div class="header-title">
                        <h5>Upload MediCare Numbers</h5>
                    </div>
                    <br />

                </div>
            </header>

            <div class="image-upload-wrap" style="width:50%">
                <input class="file-upload-input" @change="csvup" @click="onclickInput($event)" ref="filesup" type='file' accept=".csv" />
                <div class="drag-text">
                    <h3>{{tempName}}</h3>
                </div>
            </div>
            <br />
            <button class="btn btn-primary" @click="uploadCSV()"> Check On Medicare Number</button>
            <br />
            <br />
        </section>

        <section>
            <header>
                <div class="header-wrapper">
                    <div class="header-title">
                        <h5>Duplicate Check On Phone Number</h5>
                    </div>
                </div>

            </header>
            <br />
            <p style="color:red;">
                !!IMPORTANT PLEASE READ. DO NOT ENTER ANY TICKET FOR THIS!<br />
                <br />
                1. When uploading your list make sure the file has header phone (header in small cases).<br />
                2. It will take long if you have a Slow Computer. Make sure to close all Chrome Tabs to free RAM on your computer.<br />
                3. This suppression list is updated every 5 minutes.<br />
                4. We do not accept duplicates based upon Medicare number. Some people have multiple phone numbers which will lead to duplicates of you only suppress based on phone number.<br />
            </p>
        </section>
        <br /><br />
        <section>
            <header>
                <div class="header-wrapper">
                    <div class="header-title">
                        <h5>Upload Phone Numbers</h5>
                    </div>
                    <br />

                </div>
            </header>

            <div class="image-upload-wrap" style="width:50%">
                <input class="file-upload-input" @change="csvupPhone" @click="onclickInput($event)" ref="filesup" type='file' accept=".csv" />
                <div class="drag-text">
                    <h3>{{tempNamePhone}}</h3>
                </div>
            </div>
            <br />
            <button class="btn btn-primary" @click="uploadCSVPhone()"> Check On Phone Number</button>
            <br />

        </section>

    </main>

</div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import Papa from 'papaparse';
import conversion from './conversion.vue';
import clicksconv from './clicksconv.vue';
import modelWindow from './Postback.vue';
import Swal from 'sweetalert2'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    name: 'home',
    components: {
        Datepicker,
        conversion,
        clicksconv,
        modelWindow,
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            userData: [],
            mediNum: '',
            phone: '',
            error: false,
            data: {
                id: '',
                first_name: '',
                last_name: '',
                user_id: '',
                dob: '',
                medi: '',
                mm: '',
                dd: '',
                yyyy: '',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                zip: '',
                gender: '',
            },
            showForm: false,
            messageErr: '',
            showError: false,
            trycount: 0,
            predheader: ["medi_num"],
            phoneheaders: ["phone"],
            file: null,
            phonefile: null,
            tempName: "Upload CSV",
            tempNamePhone: "Upload CSV",
            phonearr1: [],
            mediarr1: [],
            loading: false
        }
    },
    mounted() {},
    computed: {
        format() {
            return 'yyyy-MM-dd'
        },

    },
    methods: {
        onLogout(event) {
            event.preventDefault();
            this.$emit('onLogout');
        },
        csvupPhone() {
            let vm = this;
            vm.phonefile = event.target.files[0];
            vm.tempNamePhone = vm.phonefile.name;
        },
        csvup() {
            let vm = this;
            vm.file = event.target.files[0];
            vm.tempName = vm.file.name;

        },
        onclickInput(event) {
            event.target.value = null;
        },
        uploadCSVPhone() {
            let vm = this;
            this.loading = true;
            const reader = new FileReader()
            reader.onload = fileLoadedEvent => {
                Papa.parse(fileLoadedEvent.target.result, {
                    header: true,
                    complete(results) {
                        let k = results.meta['fields'].toString();
                        vm.headers = k.split(',');
                        if (JSON.stringify(vm.headers) != JSON.stringify(vm.phoneheaders)) {
                            Swal.fire({
                                title: 'Error',
                                text: "Header row of your file do not match with the header row required.",
                                icon: 'error',
                            });
                            vm.phonefile = null;
                            vm.tempNamePhone = "Upload CSV";
                            vm.loading = false;
                        } else {
                            vm.checkDuplicateOnPhone(results.data);
                        }
                        vm.headers = results.meta['fields'];
                        vm.totalrow = results.data.length;
                    },
                    error(errors) {
                        console.log('error', errors)
                        this.loading = false;
                    }
                })
            }
            reader.readAsText(vm.phonefile)
        },
        uploadCSV() {
            let vm = this;
            this.loading = true;
            const reader = new FileReader()
            reader.onload = fileLoadedEvent => {
                Papa.parse(fileLoadedEvent.target.result, {
                    header: true,
                    complete(results) {
                        let k = results.meta['fields'].toString();
                        vm.headers = k.split(',');
                        if (JSON.stringify(vm.headers) != JSON.stringify(vm.predheader)) {
                            Swal.fire({
                                title: 'Error',
                                text: "Header row of your file do not match with the header row required.",
                                icon: 'error',
                            });
                            vm.file = null;
                            vm.tempName = "Upload CSV";
                            vm.loading = false;
                        } else {
                            vm.checkDuplicateOnMedi(results.data);
                        }
                        vm.headers = results.meta['fields'];
                        vm.totalrow = results.data.length;
                    },
                    error(errors) {
                        console.log('error', errors)
                        this.loading = false;
                    }
                })
            }
            reader.readAsText(vm.file)

        },
        checkDuplicateOnPhone(medi) {
            let arr1 = [];
            let vm = this;
            medi.forEach(e => {
                if (this.phonearr1.includes(e.phone)) {
                    arr1.push({
                        phone: e.phone,
                        stat: "duplicate"
                    });
                } else {
                    arr1.push({
                        phone: e.phone,
                        stat: "unique"
                    });
                }
            });
            var csv = Papa.unparse(arr1);
            var csvData = new Blob([csv], {
                type: 'text/csv;charset=utf-8;'
            });
            var csvURL = null;
            if (navigator.msSaveBlob) {
                csvURL = navigator.msSaveBlob(csvData, 'phone_stat.csv');
            } else {
                csvURL = window.URL.createObjectURL(csvData);
            }
            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'phone_stat.csv');
            tempLink.click();
            this.loading = false;
        },
        checkDuplicateOnMedi(medi) {
            let arr1 = [];
            let vm = this;
            let reg = /^[1-9]{1}[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz]{1}[0-9]{1}-?[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz]{1}[0-9]{1}-?[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz|^0-9]{1}[0-9]{1}[0-9]{1}$/i;
            var rule = /\s{1,}/g;
            medi.forEach(e => {
                if (reg.test(e.medi_num.split(rule).join(""))) {
                    if (this.mediarr1.includes(e.medi_num.split(rule).join(""))) {
                        arr1.push({
                            medi_num: e.medi_num.split(rule).join(""),
                            stat: "duplicate"
                        });
                    } else {
                        arr1.push({
                            medi_num: e.medi_num,
                            stat: "unique"
                        });
                    }
                } else {
                    arr1.push({
                        medi_num: e.medi_num,
                        stat: "Invalid format."
                    });
                }

            });
            var csv = Papa.unparse(arr1);
            var csvData = new Blob([csv], {
                type: 'text/csv;charset=utf-8;'
            });
            var csvURL = null;
            if (navigator.msSaveBlob) {
                csvURL = navigator.msSaveBlob(csvData, 'medid_stat.csv');
            } else {
                csvURL = window.URL.createObjectURL(csvData);
            }
            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'medi_stat.csv');
            tempLink.click();
            this.loading = false;
        },
        onLogout(event) {
            event.preventDefault();
            this.$emit('onLogout');
        },

    },
    created() {
        fetch('https://teoassets.s3.us-east-2.amazonaws.com/dupes/med.json')
            .then((response) => response.json())
            .then((data) => this.mediarr1 = data);

        fetch('https://teoassets.s3.us-east-2.amazonaws.com/dupes/phone.json')
            .then((response) => response.json())
            .then((data) => this.phonearr1 = data);

        this.userData = this.$store.getters.getuserData;

    }
}
</script>

<style>
#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0099ff;
    color: white;
}

.testme {
    height: 100px;
}

@media screen and (max-width: 600px) {

    .hidemobile {

        display: none;

    }

}

img {
    width: 100%;
    height: auto;
}

.m_title:first-letter {
    text-transform: uppercase
}

#menu-toggle {
    display: none;
}

#menu-toggle:checked~.sidebar {
    left: -345px;
}

#menu-toggle:checked~.main-content {
    margin-left: 0;
    width: 100vw;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    display: none;
    background-color: rgba(255, 255, 255, 0.5);
}

/* helper */
.text-danger {
    color: red;
}

.text-success {
    color: #2ec3a3;
}

.text-main {
    color: var(--color-main);
}

/*
Sidebar
*/
.sidebar {
    width: 20%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 1rem 1.2rem;
    transition: left 300ms;
}

.sidebar-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: var(--shadow);
    padding: 1.2rem;
    overflow-y: auto;
}

.sidebar-container::-webkit-scrollbar {
    width: 5px;
}

.sidebar-container::-webkit-scrollbar-track {
    box-shadow: var(--shadow);
}

.sidebar-container::-webkit-scrollbar-thumb {
    background-color: var(--main-accent);
    outline: 1px solid #ccc;
    border-radius: 2px;
}

.brand {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand h3 {
    color: #444;
    font-size: 3rem;
}

.brand h3 span {
    color: var(--color-main);
    font-size: 2.5rem;
    display: inline-block;
    margin-right: 0.5rem;
}

.sidebar-avatar {
    display: grid;
    grid-template-columns: 70px auto;
    align-items: center;
    border: 2px solid var(--main-accent);
    padding: 0.1rem 0.7rem;
    border-radius: 7px;
    margin: 2rem 0rem;
}

.sidebar-avatar img {
    width: 40px;
    border-radius: 10px;
    margin: 5px 0;
}

.avatar-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-menu li {
    margin-bottom: 2rem;
}

.sidebar-menu a {
    color: var(--main-text);
    display: block;
    padding: 0.7rem 0;
}

.sidebar-menu a.active {
    background-color: var(--main-accent);
    border-radius: 7px;
    padding: 0.8rem;
}

.sidebar-menu a span:first-child {
    display: inline-block;
    margin-right: 0.8rem;
    font-size: 1.5rem;
    color: var(--color-main);
}

.sidebar-card {
    background-color: var(--main-accent);
    padding: 1rem;
    margin-top: 2rem;
    box-shadow: var(--shadow);
    text-align: center;
    border-radius: 7px;
}

.side-card-icon span {
    font-size: 8rem;
    color: var(--color-main);
    display: inline-block;
}

.side-card-icon {
    margin-bottom: 0.8rem;
}

.side-card-icon+div {
    margin-bottom: 1rem;
}

.side-card-icon+div p {
    font-size: 0.8rem;
    color: #555;
}

.btn {
    padding: 0.7rem 1rem;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.btn span {
    font-size: 1.2rem;
    display: inline-block;
    margin-right: 0.7rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-main {
    background-color: var(--color-main);
    color: #fff;
}

/* main */
.main-content {
    width: 100%;
    margin-left: 20%;
    width: calc(100vw - 345px);
    padding: 1rem 2rem 1.2rem 1.2rem;
    transition: margin-left 300ms;
}

/* Header */
header {
    display: flex;
    padding-top: 0.5rem;
    justify-content: space-between;
}

.header-wrapper {
    display: flex;
}

.header-wrapper label {
    display: inline-block;
    color: var(--color-main);
    margin-right: 2rem;
    font-size: 1.8rem;
}

.header-wrapper label span:hover {
    cursor: pointer;
}

.header-title h1 {
    color: var(--main-text);
    font-weight: 600;
}

.header-title p {
    color: #666;
    font-size: 0.9rem;
}

.header-title p span {
    color: red;
    font-size: 1.2rem;
    display: inline-block;
    margin-left: 0.5rem;
}

.header-action button {
    padding: 0.85rem 2rem;
    font-size: 1rem;
}

main {
    padding: 2.5rem 0;
}

.analytics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2.5rem;
    margin-bottom: 3rem;
}

.analytic {
    box-shadow: var(--shadow);
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    padding-left: 2rem;
}

.analytic-info h4 {
    font-weight: 400;
    color: #555;
    font-size: 0.98rem;
}

.analytic-info h1 {
    color: var(--main-text);
    font-weight: 600;
}

.analytic-info h1 small {
    font-size: 0.8rem;
    font-weight: 700;
}

.analytic-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 0.8rem;
}

.section-head {
    font-size: 1.4rem;
    color: var(--main);
    font-weight: 600;
    margin-bottom: 1rem;
}

.block-grid {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 30% auto;
}

.rev-content {
    background-color: #fff;
    box-shadow: var(--shadow);
    border-radius: 15px;
    padding: 1rem 2rem;
    text-align: center;
}

.rev-content img {
    width: 180px;
    margin: 1.5rem 0;
    border-radius: 50%;
}

.rev-info {
    margin-bottom: 1rem;
}

.rev-info h3,
.rev-sum h4 {
    font-weight: 600;
    color: var(--main);
}

.rev-info h1,
.rev-sum h2 {
    font-weight: 400;
    margin-top: 0.4rem;
    color: #555;
}

.rev-info h1 small {
    font-size: 1rem;
}

.rev-sum {
    background-color: var(--main-accent);
    border: 1px solid var(--color-main);
    padding: 1rem;
    border-radius: 10px;
}

.graph-content {
    border-radius: 15px;
    box-shadow: var(--shadow);
    padding: 1rem;
    background-color: #fff;
}

.graph-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

.icon {
    height: 40px;
    width: 40px;
    border-radius: 7px;
    background-color: #eee;
    display: grid;
    place-items: center;
    font-size: 1.2rem;
    margin-right: 0.8rem;
}

.graph-head select {
    outline: none;
    background-color: #eee;
    height: 35px;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    border: none;
}

.overlay label {
    display: block;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1224px) {
    .sidebar {
        left: -345px;
        z-index: 30;
    }

    .main-content {
        width: 100vw;
        margin-left: 0;
    }

    #menu-toggle:checked~.sidebar {
        left: 0;
    }

    #menu-toggle:checked~.overlay {
        display: block;
        text-align: right;
    }
}

@media only screen and (max-width: 860px) {
    .analytics {
        grid-template-columns: repeat(2, 1fr);
    }

    .block-grid {
        grid-template-columns: 100%;
    }

    .revenue-card {
        order: 2;
    }
}

@media only screen and (max-width: 580px) {
    .analytics {
        grid-template-columns: 100%;
    }
}

@media only screen and (max-width: 500px) {

    .header,
    header-wrapper {
        align-items: center;
    }

    .header-title h1 {
        font-size: 1.2em;
    }

    .header-title p {
        display: none;
    }
}

.image-upload-wrap {
    margin-top: 20px;
    border: 4px dashed #0099ff;
    position: relative;
    height: 150px;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.drag-text {
    text-align: center;
    overflow: hidden;
}

#wrapper {
    width: 740px;
}

#div1 {
    display: inline-block;
    width: 320px;
}

#div2 {
    display: inline-block;
    padding-left: 25px;
    width: 160px;
}

.drag-text {
    text-align: center;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.icimgs {
    width: 35px !important;
    height: 35px !important;
}
</style>
