import { createApp } from 'vue'
import App from './App.vue'
import router from './router' 
import store  from "./store";

import axios from "axios";
import VueAxios from 'vue-axios'

import FloatingVue from 'floating-vue'

import 'floating-vue/dist/style.css'

window.axios = require('axios');
axios.defaults.baseURL = "https://dashboard.arlo.ai/api/"
axios.defaults.headers.get['Accepts'] = 'application/json'
axios.interceptors.request.use(
    (config) => {
      let token = store.getters.authToken;
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
  
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response.status === 401 || error.response.status === 419) {
        localStorage.removeItem('_rmain_key_');
        window.location.href="/";
    }
    return error;
  });


createApp(App).use(VueAxios, axios).use(store).use(router).use(FloatingVue).mount('#app')