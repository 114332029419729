import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import router from '../router';

export default createStore({

    state: {
        title: "Vuex Store",
        accessToken: null,
        loggingIn: false,
        loginError: null,
        userData: null,
        FBToken: null,
        FBPage: null,
        userName: null,
        profileImage: null,
        onboarding: 0,
        partners: null,
        UserDetails: null,
        UserDomains: null,
        UserTokens: null,
        allUsers:[],
    },
    getters: {
        isAuthenticated(state) {
            return state.accessToken !== null
        },
        isAdmin(state) {
            return state.userData.is_admin === 1
        },
        authToken(state) {
            return state.accessToken
        },
        isAdmin(state) {
            return state.userData.is_admin === 2;
          },
        getuserData(state) {
            return state.userData;
          },
        getaffiliate(state) {
            return state.allUsers;
        },
    },
    mutations: {
        updateAccessToken: (state, accessToken) => {
            state.accessToken = accessToken;
        },
        loginStart: state => state.loggingIn = true,
        updateUserData: (state, userData) => {
            state.userData = userData;
          },
        setUsersinstate: (state, userData) => {
            state.allUsers = userData;
          },
    },
    actions: {
        doLogout({ commit }, data) {
            return new Promise((resolve, reject) => {
                let token = data.token;
                axios.get("https://dashboard.arlo.ai/api/logout", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }).then(res => {
                    localStorage.removeItem('_rmain_key_');
                    commit('updateAccessToken', null);
                    commit('updateUserData', null);
                    commit('logout');
                    router.push({ name: 'Login' });
                }).catch(error => {

                    reject(error)
                });
            });
        },
        doLogin({ commit }, loginData) {
            console.log("firing");
            return new Promise((resolve, reject) => {
              
                axios.post('https://dashboard.arlo.ai/api/login', {
                    password: loginData.password,
                    email: loginData.username,
                })
                    .then(response => {
                        if (response.data.status == 'success') {
                            commit('loginStart');
                            commit('updateAccessToken', response.data.access_token);
                            commit('updateUserData', response.data.user);
                        } else {
                            commit('updateAccessToken', null);
                            reject(response.data)
                        }

                    })
                    .catch(error => {
                        
                    })
            })
        },
        setAffiliate({ commit }, users){
            commit('setUsersinstate', users);
        }
    },
    plugins: [
        createPersistedState({
            key: '_rmain_key_',
        })
    ],
});

