<template>
<div v-bind:class="{ 'hideDiv': !loading}">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#1d3f72" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
    </svg>
</div>
<CreateCampaigns v-if="showCampDialog" @closeDialog="getAllCampaigns"></CreateCampaigns>
<CampaignsLink v-if="showLinks" :name="campaignname" :offers="offers" :user="userData.vici_agent"  @closeDialog="showLinks=false;"></CampaignsLink>

<div class="sidebar">
    <div class="sidebar-container">
        <div class="brand">
            <img src="@/assets/logonew.png" style="width:100px">
        </div>
        <div class="sidebar-avatar">
            <div>
                <img src="@/assets/user.jpeg" alt="avatar">
            </div>
            <div class="avatar-info">
                <div class="avatar-text">
                    <h6>{{userData.name}}</h6>
                </div>
            </div>
        </div>
        <div class="sidebar-menu">
            <ul>
                <li><a href="#" @click.prevent="active_Page=1" v-bind:class="{ 'active': active_Page==1}"><span class="las la-chart-bar"></span><span>Dashboard</span></a></li>
                <li><a href="#" @click.prevent="getAllCampaigns()" v-bind:class="{ 'active': active_Page==2}"><span class="las la-chart-bar"></span><span>Campaigns</span></a></li>
                <li><a href="#" @click="onLogout($event)"><span class="las la-power-off"></span><span>Logout</span></a></li>
            </ul>
            <ul>

            </ul>
        </div>
    </div>
</div>
<div class="main-content" style="justify-content:left;" v-bind:class="{ 'hideDiv': loading}" v-if="active_Page==1">
    <main style="width:100%">
        <header>
            <div class="header-wrapper">
                <div class="header-title">
                    <h5>Offers &amp; Conversion Stats</h5>

                </div>
            </div>
            <div class="header-action">
                <!--<div class="analytic-icon"><a href=""  @click="onLogout($event)"><img src="@/assets/logout.png"></a></div>-->
            </div>
        </header>
        <br />
        <section>
            <div style="padding-bottom:20px;max-width:50%">
                <Datepicker v-model="date" range multiCalendars @blur="updateDate" :format="format" />
            </div>
        </section>
        <section>
            <div class="tabcontent">
                <table id="customers" :key="convKey">
                    <tr>
                        <th>Offer</th>
                        <th>Link</th>
                        <th>Price</th>
                        <th>Conversion</th>
                    </tr>
                    <tbody class="testme">
                        <tr v-for="r in offers" :key="r.id">
                            <td>{{r.Name}}</td>
                            <td @click="copyLink($event,getLink(r.link))">{{getLink(r.link)}}</td>
                            <td>${{r.pricing}}</td>
                            <td>
                                <conversion :name="r.Name" :date="date" :source="userData.vici_agent"></conversion>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <p style="font-size:12px;color:red;"><sup>*</sup> Click To Copy Link</p>
        </section>
    </main>
    <div id="snackbar">Link Copied</div>
</div>

<div class="main-content" style="justify-content:left;" v-bind:class="{ 'hideDiv': loading}" v-if="active_Page==2">
    <main style="width:80%">
        <header>
            <div class="header-wrapper">
                <div class="header-title">
                    <h5>Campaigns</h5>
                </div>
            </div>
            <div class="header-action">
                <button class="btn btn-primary" @click.prevent="showCampDialog=true;" name="submit">Create Campaign</button>
            </div>
        </header>
        <br />
        <section>
            <div class="tabcontent" v-if="campaigns.length>0">
                <table id="customers" :key="convKey">
                    <tr>
                        <th>Campaigns Name</th>
                        <th>Links</th>
                    </tr>
                    <tbody class="testme" v-for="r in campaigns" :key="r.id">
                        <tr>
                            <td>{{r.campaign}}</td>
                            <td><button name="submit" @click.prevent="campaignname=r.campaign; showLinks=true" class="btn btn-primary">View Links</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="tabcontent" v-else>
                <p> No Campaigns Found</p>
            </div>
            <br />
        </section>
    </main>
    <div id="snackbar">Link Copied</div>
</div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import Papa from 'papaparse';
import conversion from './conversion.vue';
import CreateCampaigns from './CreateCampaigns.vue';
import CampaignsLink from './CampaignsLink.vue';

export default {
    name: 'home',
    components: {
        Datepicker,
        conversion,
        CreateCampaigns,
        CampaignsLink
    },
    data() {
        return {
            offers: [],
            loading: true,
            date: null,
            graphKey: 1,
            visitData: [],
            shannon: 0,
            conversationData: [],
            sold: [],
            qConver: 0,
            active_el: 1,
            active_Page: 1,
            userData: [],
            convKey: 1,
            showCampDialog: false,
            campaigns: [],
            showLinks:false,
        }
    },
    mounted() {},
    computed: {
        format() {
            return 'yyyy-MM-dd'
        },
    },
    methods: {
        
        getAllCampaigns() {
            this.axios.get("/campaign").then(response => {
                this.campaigns = response.data.campaigns;
                this.active_Page = 2;
                this.showCampDialog=false;
            })

        },
        getLink(link) {

            if (link == 'n/a') {
                return "n/a";
            } else if (link.includes("therategenie")) {
                return link + "?utm_source=" + this.userData.vici_agent;
            } else {
                return link + this.userData.vici_agent;
            }
        },
        onLogout(event) {
            event.preventDefault();
            this.$emit('onLogout');
        },
        downloadCsv(event) {
            event.preventDefault();
            let yourDate = new Date()
            let filename = "Logs-" + yourDate.toISOString().split('T')[0] + ".csv";

            var csv = Papa.unparse(this.getLogData);
            var csvData = new Blob([csv], {
                type: 'text/csv;charset=utf-8;'
            });
            var csvURL = null;
            if (navigator.msSaveBlob) {
                csvURL = navigator.msSaveBlob(csvData, filename);
            } else {
                csvURL = window.URL.createObjectURL(csvData);
            }
            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', filename);
            tempLink.click();
        },
        copyLink(event, link) {
            event.preventDefault();
            const el = document.createElement('textarea');
            el.value = link;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            var x = document.getElementById("snackbar");
            x.className = "show";
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 3000);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
        },

        convert(str) {
            var date = new Date(str),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        },
        updateDate() {

            localStorage.setItem('start', this.convert(this.date[0]));
            localStorage.setItem('end', this.convert(this.date[1]));
            this.date = [localStorage.getItem('start'), localStorage.getItem('end')];
            this.convKey++;
        },

        getNameOfMonth(dt) {
            let mlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return mlist[dt.getMonth()];
        },

        read() {
            let vm = this;
            vm.loading = true;

            let data = {
                startDate: localStorage.getItem('start'),
                endDate: localStorage.getItem('end'),
            }
            vm.axios.get("/dash/offers", ).then(response => {
                this.offers = response.data.offers.pricing;
                this.loading = false;
            })
        }
    },
    created() {
        this.date = [localStorage.getItem('start'), localStorage.getItem('end')];
        this.userData = this.$store.getters.getuserData;
        this.read();
    }
}
</script>
