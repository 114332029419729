<template>
<input type="checkbox" name="" id="menu-toggle">
<div class="overlay"><label for="menu-toggle">
    </label></div>

<div class="sidebar">
    <div class="sidebar-container">
        <div class="brand">
            <img src="@/assets/logonew.png" style="width:100px">
        </div>
        <div class="sidebar-avatar">
            <div>
                <img src="@/assets/ray.png" alt="avatar" v-if="userData.id==126">
                <img src="@/assets/user.jpeg" alt="avatar" v-else>
            </div>
            <div class="avatar-info">
                <div class="avatar-text">
                    <h6>{{userData.name}}</h6>
                    <small>Admin</small>
                </div>
            </div>
        </div>
        <div class="sidebar-menu">
            <ul>
                <li><router-link to="/"><span class="las la-users"></span><span>Home</span></router-link></li>
                <li><a href="#" @click="onLogout($event)"><span class="las la-power-off"></span><span>Logout</span></a></li>
            </ul>
            <ul>

            </ul>
        </div>
    </div>
</div>
<div>
    <div class="main-content">
        <main>
            <section>
                <div class="header-wrapper">
                    <div class="header-title">
                        <h5><label for="menu-toggle" style="color:#ff118f; font-size:24px;"><span class="las la-bars"></span></label>Stats</h5>
                    </div>
                </div>
                <br />
            </section>
            <br />
            <section class="hidemobile">
                <div class="row">
                <div class="col-6">
                <header>
                    <div class="header-wrapper">
                        <div class="header-title">
                            <h5>Today</h5>
                        </div>
                    </div>
                </header>
                <br />
                <table id="customers">
                    <tr>
                        <th>Offer Name</th>
                        <th>Conversions</th>
                        <th>Form Fills</th>
                        <th>Revenue Generated</th>
                    </tr>
                    <tbody class="testme">
                        <tr v-for="(value, name, index) in allData.conversions.today" :key="index+100">
                            <td>{{name}}</td>
                            <td>{{value}}</td>
                            <td>
                                {{getFormFillsToday(name, 'today')}}
                            </td>
                            <td>
                                ${{getDollarAmount(name,value)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <div class="col-6">
                <header>
                    <div class="header-wrapper">
                        <div class="header-title">
                            <h5>Yesterday</h5>
                        </div>
                    </div>
                </header>

                <br />
                <table id="customers">
                    <tr>
                        <th>Offer Name</th>
                        <th>Conversions</th>
                        <th>Form Fills</th>
                         <th>Revenue Generated</th>
                    </tr>
                    <tbody class="testme">
                        <tr v-for="(value, name, index) in allData.conversions.yesterday" :key="index+100">
                            <td>{{name}}</td>
                            <td>{{value}}</td>
                            <td>
                                {{getFormFillsToday(name, 'yesterday')}}
                            </td>
                            <td>
                                ${{getDollarAmount(name,value)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                </div>
            </section>
            <br/><br/><br/>
            <section class="hidemobile">
                <div class="row">
                <div class="col-6">
                <header>
                    <div class="header-wrapper">
                        <div class="header-title">
                            <h5>Week</h5>
                        </div>
                    </div>
                </header>
                <br />
                <table id="customers">
                    <tr>
                        <th>Offer Name</th>
                        <th>Conversions</th>
                        <th>Form Fills</th>
                        <th>Revenue Generated</th>
                    </tr>
                    <tbody class="testme">
                        <tr v-for="(value, name, index) in allData.conversions.week" :key="index+100">
                            <td>{{name}}</td>
                            <td>{{value}}</td>
                            <td>
                                {{getFormFillsToday(name, 'week')}}
                            </td>
                            <td>
                                ${{getDollarAmount(name,value)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <div class="col-6">
                <header>
                    <div class="header-wrapper">
                        <div class="header-title">
                            <h5>Month</h5>
                        </div>
                    </div>
                </header>

                <br />
                <table id="customers">
                    <tr>
                        <th>Offer Name</th>
                        <th>Conversions</th>
                        <th>Form Fills</th>
                        <th>Revenue Generated</th>
                    </tr>
                    <tbody class="testme">
                        <tr v-for="(value, name, index) in allData.conversions.month" :key="index+100">
                            <td>{{name}}</td>
                            <td>{{value}}</td>
                            <td>
                                {{getFormFillsToday(name, 'month')}}
                            </td>
                            <td>
                                ${{getDollarAmount(name,value)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                </div>
            </section>
        </main>
    </div>
</div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import Papa from 'papaparse';
import modelWindow from './modelWindow.vue';
import DetailsWindow from './DetailsWindow.vue';
import loader from './loader.vue';
import Swal from 'sweetalert2'

export default {
    name: 'admin-home',
    components: {
        Datepicker,
        modelWindow,
        DetailsWindow,
        loader
    },
    data() {
        return {
            showModel: false,
            totalVisits: 0,
            approved: 0,
            formVisits: 0,
            reportVisits: 0,
            conversion: 0,
            loading: true,
            date: null,
            graphKey: 1,
            visitData: [],
            shannon: 0,
            conversationData: [],
            sold: [],
            source: 'mortgageorganic',
            users: [],
            active_el: 1,
            name: '',
            selectedAffiliate: '',
            showMessage: true,
            userData: [],
            showModelDetails: false,
            selectedSource: '',
            allData: [],
            loaded: false,
        }
    },
    mounted() {},
    computed: {
        getUsers() {
            return this.users;
        },
        format() {
            return 'yyyy-MM-dd'
        },
        checkOrganic() {
            if (localStorage.getItem('source') == 'mortgageorganic') {
                return true;
            }
            return false;
        },
    },
    methods: {
        getDollarAmount(name, val){
            if(name=='Lendgo'){
                return 24*val;
            }else if(name=='HomeLoanAnalyst No SSN Req'){
                return 65*val;
            }else if(name=='Enhanced Refi Short Form'){
                return 36*val;
            }else if(name=='Quicken Loans Purchase'){
                return 50*val;
            }else if(name=='NAF Email'){
                return 80*val;
            }else{
                return 0;
            }
        },
        getFormFillsToday(name, val){
           let k = 0;
           let l = [];
           if(val=='today'){
               l = this.allData.fills.today;
           }else if(val=='yesterday'){
               l = this.allData.fills.yesterday;
           }else if(val=='week'){
               l = this.allData.fills.week;
           }else if(val=='month'){
               l = this.allData.fills.month;
           }
           for(const urls of l){
               if(urls.url==name){
                   return urls.count;
               }
           }
        },      
        onLogout(event) {
            event.preventDefault();
            this.$emit('onLogout');
        },
        read() {
            this.loading= true;
            this.axios.get("https://arlo.tycoonmach.net/api/getformfill").then(response => {
                this.allData = response.data;                
            })
        },
    },
    created() {
        
        this.read();
    }
}
</script>

<style scoped>
img {
    width: 100%;
    height: auto;
}

.m_title:first-letter {
    text-transform: uppercase
}

#menu-toggle {
    display: none;
}

#menu-toggle:checked~.sidebar {
    left: -345px;
}

#menu-toggle:checked~.main-content {
    margin-left: 0;
    width: 100vw;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    display: none;
    background-color: rgba(255, 255, 255, 0.5);
}

/* helper */
.text-danger {
    color: red;
}

.text-success {
    color: #2ec3a3;
}

.text-main {
    color: var(--color-main);
}

/*
Sidebar
*/
.sidebar {
    width: 345px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 1rem 1.2rem;
    transition: left 300ms;
}

.sidebar-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: var(--shadow);
    padding: 1.2rem;
    overflow-y: auto;
}

.sidebar-container::-webkit-scrollbar {
    width: 5px;
}

.sidebar-container::-webkit-scrollbar-track {
    box-shadow: var(--shadow);
}

.sidebar-container::-webkit-scrollbar-thumb {
    background-color: var(--main-accent);
    outline: 1px solid #ccc;
    border-radius: 2px;
}

.brand {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand h3 {
    color: #444;
    font-size: 3rem;
}

.brand h3 span {
    color: var(--color-main);
    font-size: 2.5rem;
    display: inline-block;
    margin-right: 0.5rem;
}

.sidebar-avatar {
    display: grid;
    grid-template-columns: 70px auto;
    align-items: center;
    border: 2px solid var(--main-accent);
    padding: 0.1rem 0.7rem;
    border-radius: 7px;
    margin: 2rem 0rem;
}

.sidebar-avatar img {
    width: 40px;
    border-radius: 10px;
    margin: 5px 0;
}

.avatar-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-menu li {
    margin-bottom: 2rem;
}

.sidebar-menu a {
    color: var(--main-text);
    display: block;
    padding: 0.7rem 0;
}

.sidebar-menu a.active {
    background-color: var(--main-accent);
    border-radius: 7px;
    padding: 0.8rem;
}

.sidebar-menu a span:first-child {
    display: inline-block;
    margin-right: 0.8rem;
    font-size: 1.5rem;
    color: var(--color-main);
}

.sidebar-card {
    background-color: var(--main-accent);
    padding: 1rem;
    margin-top: 2rem;
    box-shadow: var(--shadow);
    text-align: center;
    border-radius: 7px;
}

.side-card-icon span {
    font-size: 8rem;
    color: var(--color-main);
    display: inline-block;
}

.side-card-icon {
    margin-bottom: 0.8rem;
}

.side-card-icon+div {
    margin-bottom: 1rem;
}

.side-card-icon+div p {
    font-size: 0.8rem;
    color: #555;
}

.btn {
    padding: 0.7rem 1rem;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.btn span {
    font-size: 1.2rem;
    display: inline-block;
    margin-right: 0.7rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-main {
    background-color: var(--color-main);
    color: #fff;
}

/* main */
.main-content {
    margin-left: 345px;
    width: calc(100vw - 345px);
    padding: 1rem 2rem 1.2rem 1.2rem;
    transition: margin-left 300ms;
}

/* Header */
header {
    display: flex;
    padding-top: 0.5rem;
    justify-content: space-between;
}

.header-wrapper {
    display: flex;
}

.header-wrapper label {
    display: inline-block;
    color: var(--color-main);
    margin-right: 2rem;
    font-size: 1.8rem;
}

.header-wrapper label span:hover {
    cursor: pointer;
}

.header-title h1 {
    color: var(--main-text);
    font-weight: 600;
}

.header-title p {
    color: #666;
    font-size: 0.9rem;
}

.header-title p span {
    color: red;
    font-size: 1.2rem;
    display: inline-block;
    margin-left: 0.5rem;
}

.header-action button {
    padding: 0.85rem 2rem;
    font-size: 1rem;
}

main {
    padding: 2.5rem 0;
}

.analytics {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2.5rem;
    margin-bottom: 3rem;
}

.analytic {
    box-shadow: var(--shadow);
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    padding-left: 2rem;
}

.analytic-info h4 {
    font-weight: 400;
    color: #555;
    font-size: 0.98rem;
}

.analytic-info h1 {
    color: var(--main-text);
    font-weight: 600;
}

.analytic-info h1 small {
    font-size: 0.8rem;
    font-weight: 700;
}

.analytic-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 0.8rem;
}

.section-head {
    font-size: 1.4rem;
    color: var(--main);
    font-weight: 600;
    margin-bottom: 1rem;
}

.block-grid {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 30% auto;
}

.rev-content {
    background-color: #fff;
    box-shadow: var(--shadow);
    border-radius: 15px;
    padding: 1rem 2rem;
    text-align: center;
}

.rev-content img {
    width: 180px;
    margin: 1.5rem 0;
    border-radius: 50%;
}

.rev-info {
    margin-bottom: 1rem;
}

.rev-info h3,
.rev-sum h4 {
    font-weight: 600;
    color: var(--main);
}

.rev-info h1,
.rev-sum h2 {
    font-weight: 400;
    margin-top: 0.4rem;
    color: #555;
}

.rev-info h1 small {
    font-size: 1rem;
}

.rev-sum {
    background-color: var(--main-accent);
    border: 1px solid var(--color-main);
    padding: 1rem;
    border-radius: 10px;
}

.graph-content {
    border-radius: 15px;
    box-shadow: var(--shadow);
    padding: 1rem;
    background-color: #fff;
}

.graph-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

.icon {
    height: 40px;
    width: 40px;
    border-radius: 7px;
    background-color: #eee;
    display: grid;
    place-items: center;
    font-size: 1.2rem;
    margin-right: 0.8rem;
}

.graph-head select {
    outline: none;
    background-color: #eee;
    height: 35px;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    border: none;
}

.overlay label {
    display: block;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1224px) {
    .sidebar {
        left: -345px;
        z-index: 30;
    }

    .main-content {
        width: 100vw;
        margin-left: 0;
    }

    #menu-toggle:checked~.sidebar {
        left: 0;
    }

    #menu-toggle:checked~.overlay {
        display: block;
        text-align: right;
    }
}

@media only screen and (max-width: 860px) {
    .analytics {
        grid-template-columns: repeat(2, 1fr);
    }

    .block-grid {
        grid-template-columns: 100%;
    }

    .revenue-card {
        order: 2;
    }
}

@media only screen and (max-width: 580px) {
    .analytics {
        grid-template-columns: 100%;
    }
}

@media only screen and (max-width: 500px) {

    .header,
    header-wrapper {
        align-items: center;
    }

    .header-title h1 {
        font-size: 1.2em;
    }

    .header-title p {
        display: none;
    }
}
.makefontsmaller{
    font-size : 12px !important;
}
</style>
