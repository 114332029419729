<template>
<div class="modal" style="padding-top:10%">
    <div class="modal-content" style="background-color:transparent;border:none;">
        <div>
            <form id="contact" @submit.prevent="submitForm">
                <span @click="CloseDialog" style="float:right;font-size:20px;cursor:pointer;">&times;</span>
                <h3>Select IVR for Debt /Personal DID </h3>
                <fieldset>
                    <select class="selectbox" v-model="url">
                      <option value="https://arlo.tycoonmach.net/api/affiliate/withoutopen" >A</option>
                      <option value="https://arlo.tycoonmach.net/api/debt/call-open" >B</option>
                      <option value="https://arlo.tycoonmach.net/api/debt/call-no-open">D</option>
                      <option value="https://arlo.tycoonmach.net/api/personal/call-receive">F</option>
                      <option value="https://arlo.tycoonmach.net/api/debt/call-fc">FkCr</option>
                      <option value="https://arlo.tycoonmach.net/api/debt/call-center">Call Center</option>
                      <option value="https://arlo.tycoonmach.net/api/debt/call-debt-adv">Debt Adv First</option>
                    </select>
                </fieldset>
                <fieldset>
                    <button name="submit" type="submit" id="contact-submit" data-submit="...Sending">Get DID</button>
                </fieldset>
            </form>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
            url: '',
        }
    },
    created(){
        console.log("sdsad");
    },
    methods:{
        appendToUrl(val){
            this.name = this.name+"{"+val+"}";
        },
        CloseDialog(){
            this.$emit("CloseDialog");
        },
        submitForm(){
            let data = {
                url: this.url,
            };
            this.$emit("getDIDDebt", data);
        }
    }
}
</script>
<style scoped>
.selectbox{
    width: 100%;
    border: 1px solid #ccc;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
}
.modal {
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    padding-top: 2px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    max-width: 500px;
    border-radius: 0.3rem;
    text-align: center;
    font-size: 11px;
    position: relative;
}


#contact {
  background: #F9F9F9;
  padding: 25px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

#contact h3 {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}

#contact h4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="password"],
#contact textarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #FFF;
  margin: 0 0 5px;
  padding: 10px;
}

#contact input[type="text"]:hover,
#contact input[type="email"]:hover,
#contact input[type="tel"]:hover,
#contact input[type="url"]:hover,
#contact textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

#contact textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

#contact button[type="submit"] {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #0099ff;
  color: #FFF;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}

#contact button[type="submit"]:hover {
  background: #43A047;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.copyright {
  text-align: center;
}

#contact input:focus,
#contact textarea:focus {
  outline: 0;
  border: 1px solid #aaa;
}

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}
</style>
