<template>
<div v-bind:class="{ 'hideDiv': !loading}">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#1d3f72" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
    </svg>
</div>
<modelWindow v-if="showPostbackModel" @CloseDialog="showPostbackModel=false;" @PostbackSaved="PostbackSaved"></modelWindow>
<div class="sidebar">
    <div class="sidebar-container">
        <div class="brand">
            <img src="@/assets/logonew.png" style="width:100px">
        </div>
        <div class="sidebar-avatar">
            <div>
                <img src="@/assets/user.jpeg" alt="avatar">
            </div>
            <div class="avatar-info">
                <div class="avatar-text">
                    <h6>{{userData.name}}</h6>
                </div>
            </div>
        </div>
        <div class="sidebar-menu">
            <ul>
                <li><a href="/medi-correction"><span class="fa fa-users"></span><span>Resubmit Leads</span></a></li>
                <li><a href="/dupecheck"><span class="fa fa-check"></span><span>Duplicate Check</span></a></li>
                <li><a href="#" @click="onLogout($event)"><span class="las la-power-off"></span><span>Logout</span></a></li>
            </ul>
        </div>
    </div>
</div>
<div class="main-content" style="width:80%" v-bind:class="{ 'hideDiv': loading}">
    <main>
        <header>
            <div class="header-wrapper">
                <div class="header-title">
                    <h5>Free Covid Test Campaign</h5>
                    <div id="wrapper">
                        <div id="div1">
                            <Datepicker v-model="date" range multiCalendars @blur="updateDate" :format="format" />
                        </div>
                    </div>

                </div>
            </div>
            <div class="header-action">
                <div class="analytic-icon" style="display:flex; font-size:18px;">
                    <a href="" @click="downloadCsv($event)" v-tooltip="'Download Leads'"><img src="@/assets/downloading-updates.png" class="icimgs"></a>&nbsp;&nbsp;&nbsp;
                    <a href="" @click="downloadCsvNoRecordings($event)" v-tooltip="'Download All Leads With No Recordings'"><img src="@/assets/downloading-updates.png" class="icimgs"></a>&nbsp;&nbsp;&nbsp;
                    <a href="https://docs.google.com/document/d/1cTdIzgr-igODkDh_FhxMtlatbu1UzH25lM3LUOTnFb0/edit?usp=sharing" target="_blank" v-tooltip="'Access Onboarding Guide'"><img src="@/assets/icons8-business-documentation-30.png" class="icimgs"></a>
                </div>

            </div>
        </header>
        <br />
        <section>
            <div class="analytics">
                <div class="analytic">
                    <div class="analytic-icon"><i class="fa fa-users" style="font-size:16px"></i></div>
                    <div class="analytic-info">
                        <h4>Submissions</h4>
                        <h5>{{totalVisits}}</h5>
                    </div>
                </div>
                <div class="analytic">
                    <div class="analytic-icon"><i class="fa fa-microphone" style="font-size:16px"></i></div>
                    <div class="analytic-info">
                        <h4>Recording Missing</h4>
                        <h5>{{totalVisits - (totalRecordings)}}</h5>
                    </div>
                </div>
                <div class="analytic">
                    <div class="analytic-icon"><i class="fa fa-microphone" style="font-size:16px"></i></div>
                    <div class="analytic-info">
                        <h4>Recording In Review</h4>
                        <h5>{{getReview()}}</h5>
                    </div>
                </div>

                <div class="analytic">
                    <div class="analytic-icon"><i class="fa fa-check" style="font-size:16px"></i></div>
                    <div class="analytic-info">
                        <h4>Approved</h4>
                        <h5>{{approved}}</h5>
                    </div>
                </div>
                <div class="analytic">
                    <div class="analytic-icon"><i class="fa fa-ban" style="font-size:16px"></i></div>
                    <div class="analytic-info">
                        <h4>Rejected</h4>
                        <h5>{{disapproved}}</h5>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <header>
                <div class="header-wrapper">
                    <div class="header-title">
                        <h5>Upload Recordings</h5>
                    </div>
                    <br />

                </div>
            </header>

            <div class="image-upload-wrap">
                <input class="file-upload-input" @change="csvup" @click="onclickInput($event)" ref="filesup" type='file' accept=".csv" />
                <div class="drag-text">
                    <h3>{{tempName}}</h3>
                </div>
            </div>
            <br />
            <button class="btn btn-primary" @click="uploadCSV()"> Upload CSV</button>
            <br />
            <p style="color:red;">
                !!IMPORTANT PLEASE READ BEFORE ENTERING A TICKET!<br />
                <br />1. When uploading your list make sure the file is CSV UTF-8 (Comma delimited) (. csv).<br />
                2. Make sure the headers of the 2 columns are phone and url in LOWERCASE. You can <a href="https://abkjh456uhk.s3.us-west-2.amazonaws.com/sample.csv" target="_blank">download this sample file</a><br />
                3. Column #1 is the 10 digit phone number with no dashes, just numerals.<br />
                4. Column #2 is recording URL. We only accept Google Drive links. No private server links or Vocaroo etc. will be accepted.
                5. Make sure you have no blank rows or phone number errors before uploading.
            </p>
        </section>
        <br /><br />
        <section>
            <header>
                <div class="header-wrapper">
                    <div class="header-title">
                        <h5>Use this Webform URL in your dialer to submit verified sign ups:</h5>
                    </div>
                </div>
            </header>

            <br />
            <p><a :href="getLink" target="_blank">{{getLink}}</a></p>
        </section>
        <!--<section>
            <header>
                <div class="header-wrapper">
                    <div class="header-title">
                        <h5>Use this form URL if the API is down and on Sunday.</h5>
                    </div>
                </div>
            </header>

            <br />
            <p><a :href="getLink2" target="_blank">{{getLink2}}</a></p>
        </section>-->
    </main>

</div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import Papa from 'papaparse';
import conversion from './conversion.vue';
import clicksconv from './clicksconv.vue';
import modelWindow from './Postback.vue';
import Swal from 'sweetalert2'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    name: 'home',
    components: {
        Datepicker,
        conversion,
        clicksconv,
        modelWindow,
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            showPostbackModel: false,
            totalVisits: 0,
            totalRecordings: 0,
            tempName: "Upload CSV",
            formVisits: 0,
            reportVisits: 0,
            conversion: 0,
            loading: true,
            date: null,
            graphKey: 1,
            visitData: [],
            shannon: 0,
            conversationData: [],
            sold: [],
            qConver: 0,
            active_el: 1,
            active_Page: 1,
            userData: [],
            logData: [],
            file: null,
            approved: 0,
            disapproved: 0,
            inreview: 0,
            predheader: ["phone", "url"],
            headers: ''
        }
    },
    mounted() {},
    computed: {
        format() {
            return 'yyyy-MM-dd'
        },
        getLink() {

            return "https://leadtrack.app/medicare/" + this.userData.id;
        },
        getLink2() {

            return "https://leadtrack.app/medicare2/" + this.userData.id;
        },
    },
    methods: {
        getReview(){
            let temp = this.totalRecordings - (this.approved + this.disapproved);
            if(temp<0){
                return 0;
            }else{
                return temp;
            }
        },
        csvup() {
            let vm = this;
            vm.file = event.target.files[0];
            if (vm.file.size > 5242880) {
                Swal({
                    type: 'error',
                    title: 'Error!',
                    text: 'CSV should be less than 5 MB.',
                });
                vm.file = null;
                return false;
            }
            vm.tempName = vm.file.name;

        },
        onclickInput(event) {
            event.target.value = null;
        },
        uploadCSV() {
            let vm = this;
            if (vm.file == null) {
                Swal.fire({
                    title: 'Error',
                    text: "Please upload a valid CSV file.",
                    icon: 'error',
                });
                return false;
            }
            const reader = new FileReader()
            reader.onload = fileLoadedEvent => {
                Papa.parse(fileLoadedEvent.target.result, {
                    header: true,
                    complete(results) {
                        let k = results.meta['fields'].toString();
                        vm.headers = k.split(',');
                        if (JSON.stringify(vm.headers) != JSON.stringify(vm.predheader)) {
                            Swal.fire({
                                title: 'Error',
                                text: "Header row of your file do not match with the header row required. Download the sample file and paste your phone and url in the columns.",
                                icon: 'error',
                            });
                            vm.file = null;
                            vm.tempName = "Upload CSV";
                        } else {
                            vm.nowUpload();
                        }
                        vm.headers = results.meta['fields'];
                        vm.totalrow = results.data.length;
                    },
                    error(errors) {
                        console.log('error', errors)
                    }
                })
            }
            reader.readAsText(vm.file)

        },
        nowUpload() {
            var fd = new FormData()
            fd.append('file', this.file);
            fd.append('id', this.userData.id);
            Swal.fire({
                title: 'Please wait',
                text: "Processing file",
                icon: 'info',

            });

            axios.post("https://arlo.tycoonmach.net/api/bucket-upload", fd, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                if (response.data.status == "success") {
                    Swal.fire({
                        title: 'Processing',
                        text: "Uploading recording. Please check back in 15 minutes",
                        icon: 'success',
                    });
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: response.data.message,
                        icon: 'error',
                    });
                }
            });
        },
        PostbackSaved() {
            this.showPostbackModel = false;
            Swal.fire({
                title: 'Success!',
                text: "URL Saved.",
                icon: 'success',

            });
        },

        onLogout(event) {
            event.preventDefault();
            this.$emit('onLogout');
        },
        downloadCsvNoRecordings(event) {

            event.preventDefault();
            let data = {
                startDate: localStorage.getItem('start'),
                endDate: localStorage.getItem('end'),
                id: this.userData.id
            }

            axios.post('https://cdn.tycoonmach.net/api/medicare/rec-download', data).then(response => {
                if (response.data.status == 'success') {
                    let temp = response.data.people;
                    var csv = Papa.unparse(temp);
                    var csvData = new Blob([csv], {
                        type: 'text/csv;charset=utf-8;'
                    });
                    var csvURL = null;
                    if (navigator.msSaveBlob) {
                        csvURL = navigator.msSaveBlob(csvData, 'leads.csv');
                    } else {
                        csvURL = window.URL.createObjectURL(csvData);
                    }
                    var tempLink = document.createElement('a');
                    tempLink.href = csvURL;
                    tempLink.setAttribute('download', 'leads.csv');
                    tempLink.click();
                }
            })
        },
        downloadCsv(event) {
            event.preventDefault();
            let data = {
                startDate: localStorage.getItem('start'),
                endDate: localStorage.getItem('end'),
                id: this.userData.id
            }

            axios.post('https://arlo.tycoonmach.net/api/medicare/download', data).then(response => {
                if (response.data.status == 'success') {
                    let temp = response.data.people;
                    var csv = Papa.unparse(temp);
                    var csvData = new Blob([csv], {
                        type: 'text/csv;charset=utf-8;'
                    });
                    var csvURL = null;
                    if (navigator.msSaveBlob) {
                        csvURL = navigator.msSaveBlob(csvData, 'leads.csv');
                    } else {
                        csvURL = window.URL.createObjectURL(csvData);
                    }
                    var tempLink = document.createElement('a');
                    tempLink.href = csvURL;
                    tempLink.setAttribute('download', 'leads.csv');
                    tempLink.click();
                }
            })
        },
        convert(str) {
            var date = new Date(str),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        },
        updateDate() {
            let vm = this;
            localStorage.setItem('start', this.convert(this.date[0]));
            localStorage.setItem('end', this.convert(this.date[1]));
            this.read();
        },
        getNameOfMonth(dt) {
            let mlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return mlist[dt.getMonth()];
        },
        read() {
            let vm = this;
            vm.loading = true;
            vm.totalVisits = 0;
            let data = {
                startDate: localStorage.getItem('start'),
                endDate: localStorage.getItem('end'),
            }
            vm.axios.post("https://arlo.tycoonmach.net/api/medicare/soldleads/" + this.userData.id, data).then(response => {
                this.totalVisits = response.data.total;
                this.approved = response.data.recordings;
                this.totalRecordings = response.data.recordings_received;
                this.disapproved = response.data.disapproved;
                this.inreview = response.data.inreview;
                this.loading = false;
            })

        }
    },
    created() {
        this.date = [localStorage.getItem('start'), localStorage.getItem('end')];
        this.userData = this.$store.getters.getuserData;
        this.read();
    }
}
</script>

<style>
#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0099ff;
    color: white;
}

.testme {
    height: 100px;
}

@media screen and (max-width: 600px) {

    .hidemobile {

        display: none;

    }

}

img {
    width: 100%;
    height: auto;
}

.m_title:first-letter {
    text-transform: uppercase
}

#menu-toggle {
    display: none;
}

#menu-toggle:checked~.sidebar {
    left: -345px;
}

#menu-toggle:checked~.main-content {
    margin-left: 0;
    width: 100vw;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    display: none;
    background-color: rgba(255, 255, 255, 0.5);
}

/* helper */
.text-danger {
    color: red;
}

.text-success {
    color: #2ec3a3;
}

.text-main {
    color: var(--color-main);
}

/*
Sidebar
*/
.sidebar {
    width: 20%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 1rem 1.2rem;
    transition: left 300ms;
}

.sidebar-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: var(--shadow);
    padding: 1.2rem;
    overflow-y: auto;
}

.sidebar-container::-webkit-scrollbar {
    width: 5px;
}

.sidebar-container::-webkit-scrollbar-track {
    box-shadow: var(--shadow);
}

.sidebar-container::-webkit-scrollbar-thumb {
    background-color: var(--main-accent);
    outline: 1px solid #ccc;
    border-radius: 2px;
}

.brand {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand h3 {
    color: #444;
    font-size: 3rem;
}

.brand h3 span {
    color: var(--color-main);
    font-size: 2.5rem;
    display: inline-block;
    margin-right: 0.5rem;
}

.sidebar-avatar {
    display: grid;
    grid-template-columns: 70px auto;
    align-items: center;
    border: 2px solid var(--main-accent);
    padding: 0.1rem 0.7rem;
    border-radius: 7px;
    margin: 2rem 0rem;
}

.sidebar-avatar img {
    width: 40px;
    border-radius: 10px;
    margin: 5px 0;
}

.avatar-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-menu li {
    margin-bottom: 2rem;
}

.sidebar-menu a {
    color: var(--main-text);
    display: block;
    padding: 0.7rem 0;
}

.sidebar-menu a.active {
    background-color: var(--main-accent);
    border-radius: 7px;
    padding: 0.8rem;
}

.sidebar-menu a span:first-child {
    display: inline-block;
    margin-right: 0.8rem;
    font-size: 1.5rem;
    color: var(--color-main);
}

.sidebar-card {
    background-color: var(--main-accent);
    padding: 1rem;
    margin-top: 2rem;
    box-shadow: var(--shadow);
    text-align: center;
    border-radius: 7px;
}

.side-card-icon span {
    font-size: 8rem;
    color: var(--color-main);
    display: inline-block;
}

.side-card-icon {
    margin-bottom: 0.8rem;
}

.side-card-icon+div {
    margin-bottom: 1rem;
}

.side-card-icon+div p {
    font-size: 0.8rem;
    color: #555;
}

.btn {
    padding: 0.7rem 1rem;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.btn span {
    font-size: 1.2rem;
    display: inline-block;
    margin-right: 0.7rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-main {
    background-color: var(--color-main);
    color: #fff;
}

/* main */
.main-content {
    width: 100%;
    margin-left: 20%;
    width: calc(100vw - 345px);
    padding: 1rem 2rem 1.2rem 1.2rem;
    transition: margin-left 300ms;
}

/* Header */
header {
    display: flex;
    padding-top: 0.5rem;
    justify-content: space-between;
}

.header-wrapper {
    display: flex;
}

.header-wrapper label {
    display: inline-block;
    color: var(--color-main);
    margin-right: 2rem;
    font-size: 1.8rem;
}

.header-wrapper label span:hover {
    cursor: pointer;
}

.header-title h1 {
    color: var(--main-text);
    font-weight: 600;
}

.header-title p {
    color: #666;
    font-size: 0.9rem;
}

.header-title p span {
    color: red;
    font-size: 1.2rem;
    display: inline-block;
    margin-left: 0.5rem;
}

.header-action button {
    padding: 0.85rem 2rem;
    font-size: 1rem;
}

main {
    padding: 2.5rem 0;
}

.analytics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2.5rem;
    margin-bottom: 3rem;
}

.analytic {
    box-shadow: var(--shadow);
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    padding-left: 2rem;
}

.analytic-info h4 {
    font-weight: 400;
    color: #555;
    font-size: 0.98rem;
}

.analytic-info h1 {
    color: var(--main-text);
    font-weight: 600;
}

.analytic-info h1 small {
    font-size: 0.8rem;
    font-weight: 700;
}

.analytic-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 0.8rem;
}

.section-head {
    font-size: 1.4rem;
    color: var(--main);
    font-weight: 600;
    margin-bottom: 1rem;
}

.block-grid {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 30% auto;
}

.rev-content {
    background-color: #fff;
    box-shadow: var(--shadow);
    border-radius: 15px;
    padding: 1rem 2rem;
    text-align: center;
}

.rev-content img {
    width: 180px;
    margin: 1.5rem 0;
    border-radius: 50%;
}

.rev-info {
    margin-bottom: 1rem;
}

.rev-info h3,
.rev-sum h4 {
    font-weight: 600;
    color: var(--main);
}

.rev-info h1,
.rev-sum h2 {
    font-weight: 400;
    margin-top: 0.4rem;
    color: #555;
}

.rev-info h1 small {
    font-size: 1rem;
}

.rev-sum {
    background-color: var(--main-accent);
    border: 1px solid var(--color-main);
    padding: 1rem;
    border-radius: 10px;
}

.graph-content {
    border-radius: 15px;
    box-shadow: var(--shadow);
    padding: 1rem;
    background-color: #fff;
}

.graph-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

.icon {
    height: 40px;
    width: 40px;
    border-radius: 7px;
    background-color: #eee;
    display: grid;
    place-items: center;
    font-size: 1.2rem;
    margin-right: 0.8rem;
}

.graph-head select {
    outline: none;
    background-color: #eee;
    height: 35px;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    border: none;
}

.overlay label {
    display: block;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1224px) {
    .sidebar {
        left: -345px;
        z-index: 30;
    }

    .main-content {
        width: 100vw;
        margin-left: 0;
    }

    #menu-toggle:checked~.sidebar {
        left: 0;
    }

    #menu-toggle:checked~.overlay {
        display: block;
        text-align: right;
    }
}

@media only screen and (max-width: 860px) {
    .analytics {
        grid-template-columns: repeat(2, 1fr);
    }

    .block-grid {
        grid-template-columns: 100%;
    }

    .revenue-card {
        order: 2;
    }
}

@media only screen and (max-width: 580px) {
    .analytics {
        grid-template-columns: 100%;
    }
}

@media only screen and (max-width: 500px) {

    .header,
    header-wrapper {
        align-items: center;
    }

    .header-title h1 {
        font-size: 1.2em;
    }

    .header-title p {
        display: none;
    }
}

.image-upload-wrap {
    margin-top: 20px;
    border: 4px dashed #0099ff;
    position: relative;
    height: 150px;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.drag-text {
    text-align: center;
    overflow: hidden;
}

#wrapper {
    width: 740px;
}

#div1 {
    display: inline-block;
    width: 320px;
}

#div2 {
    display: inline-block;
    padding-left: 25px;
    width: 160px;
}

.drag-text {
    text-align: center;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.icimgs {
    width: 35px !important;
    height: 35px !important;
}
</style>
