<template>
<div class="modal" style="padding-top:10%">
    <div class="modal-content" style="background-color:transparent;border:none;">
        <div>
            
            <form id="contact" @submit.prevent="submitForm">
                <span @click="CloseDialog" style="float:right;font-size:20px;cursor:pointer;">&times;</span>
                <h3>Add Offer </h3>
                <fieldset>
                    <input placeholder="Enter Offer Name" v-model="offer_name" type="text" tabindex="1" required autofocus>
                </fieldset>
                <fieldset>
                    <input placeholder="Enter URL" v-model="offer_link" type="text" tabindex="2" required>
                </fieldset>
                <fieldset>
                    <select class="selectbox" v-model="offer_type" required>
                      <option value="1">Mortgage</option>
                      <option value="2">Debt</option>
                    </select>
                </fieldset>
                <fieldset>
                    <select class="selectbox" v-model="offer_company" required>
                      <option value="0">Abrubt</option>
                      <option value="1">Click</option>
                      <option value="5">RateGenie</option>
                    </select>
                </fieldset>
                <fieldset>
                    <button name="submit" type="submit" id="contact-submit" data-submit="...Sending">Submit</button>
                </fieldset>
                <fieldset>
                    <p style="color:red">Do not include s1 , s2 or sub1 etc parameter. <b>DO NOT ADD OFFER IF YOU ARE NOT SURE HOW THIS WORKS.</b></p>
                </fieldset>
            </form>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
            offer_name: '',
            offer_type: '1',
            offer_link: '',
            offer_company: '0',
        }
    },
    methods:{
        CloseDialog(){
            this.$emit("CloseDialog");
        },
        submitForm(){
            let data = {
                offer_name: this.offer_name,
                offer_type: this.offer_type,
                offer_link: this.offer_link,
                offer_company: this.offer_company
            };
            this.axios.post("https://arlo.tycoonmach.net/api/offers", data).then(response => {
                this.$emit("readOffers");
            })
        }
    }
}
</script>
<style scoped>
.selectbox{
    width: 100%;
    border: 1px solid #ccc;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
}
.modal {
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    padding-top: 2px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    max-width: 500px;
    border-radius: 0.3rem;
    text-align: center;
    font-size: 11px;
    position: relative;
}


#contact {
  background: #F9F9F9;
  padding: 25px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

#contact h3 {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}

#contact h4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="password"],
#contact textarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #FFF;
  margin: 0 0 5px;
  padding: 10px;
}

#contact input[type="text"]:hover,
#contact input[type="email"]:hover,
#contact input[type="tel"]:hover,
#contact input[type="url"]:hover,
#contact textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

#contact textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

#contact button[type="submit"] {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #0099ff;
  color: #FFF;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}

#contact button[type="submit"]:hover {
  background: #43A047;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.copyright {
  text-align: center;
}

#contact input:focus,
#contact textarea:focus {
  outline: 0;
  border: 1px solid #aaa;
}

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}
</style>
